import Vue from 'vue';
import Vuex from 'vuex';

import {api} from '@/boot/api';
import menu from './modules/menu';
import user from './modules/user';
import chat from './modules/chat';
import todo from './modules/todo';
import survey from './modules/survey';
import createUsersModule from './users';
import createGlobalModule from './global';
import createPlacesModule from './places';
import createCitiesModule from './cities';
import createSessionModule from './session';
import createRegionsModule from './regions';
import createVendorsModule from './vendors';
import createTicketsModule from './tickets';
import createMessagesModule from './messages';
import createMessengerModule from './messenger';
import createSalesMansModule from './sales-mans';
import createVendorPlaceEditorModule from './vendor-place-editor';
import createTicketAutoProcessorModule from './ticket-auto-processor';
import createVlUsersModule from './vl-users';
import createVlPlansModule from './vl-plans';
import createCommandsModule from './commands';
import createVlPlacesModule from './vl-places';
import createVlCitiesModule from './vl-cities';
import createVlPeriodsModule from './vl-periods';
import createVlRegionsModule from './vl-regions';
import createVlTicketsModule from './vl-tickets';
import createVlVendorsModule from './vl-vendors';
import {messageFromError} from '@/utils/index.js';
import createVlProductsModule from './vl-products';
import createVlCampaignsModule from './vl-campaigns';
import createVlSalesMansModule from './vl-sales-mans';
import createVlPlaceTypesModule from './vl-place-types';
import createVlAdmEntitiesModule from './vl-adm-entities';
import createCollectionFilterModule from './collection-filter';
import createVlPeriodProductsModule from './vl-period-products';
import createVlSelectorPlacesModule from './vl-selector-places';
import createVlSelectorVendorsModule from './vl-selector-vendors';
import createVlCampaignProductsModule from './vl-campaign-products';
import createVlRegionalManagersModule from './vl-regional-managers';
import createVlDupUsersForRouteModule from './vl-dup-users-for-route';
import createVlSelectorSalesMansModule from './vl-selector-sales-mans';
import createVlDupPlacesForRouteModule from './vl-dup-places-for-route';
import createVlSelectorRegionalManagersModule from './vl-selector-regional-managers';

Vue.use(Vuex);

export const onSuccess = ({action}) => {

  if (
    action === 'FETCH_ITEM' ||
    action === 'FETCH_ITEMS'
  ) return;

  Vue.prototype.$notify(
    'success',
    'Операция выполнена',
    undefined,
    {duration: 3000, permanent: false},
  );
};

export const onError = error => {
  Vue.prototype.$notify(
    'error',
    'Ошибка',
    messageFromError(error),
    {duration: 3000, permanent: false},
  );
}

const options = {
  client: api,
  onSuccess,
  onError,
};

export const store = new Vuex.Store({
  modules: {
    menu,
    user,
    chat,
    todo,
    survey,
    users: createUsersModule(),
    global: createGlobalModule(),
    cities: createCitiesModule(),
    places: createPlacesModule(),
    regions: createRegionsModule(),
    vendors: createVendorsModule(),
    tickets: createTicketsModule(),
    session: createSessionModule(),
    messages: createMessagesModule(),
    commands: createCommandsModule(),
    salesMans: createSalesMansModule(),
    messenger: createMessengerModule(),
    vendorPlaceEditor: createVendorPlaceEditorModule(),
    ticketAutoProcessor: createTicketAutoProcessorModule(),
    vlPlans: createVlPlansModule(options),
    vlUsers: createVlUsersModule(options),
    vlCities: createVlCitiesModule(options),
    vlPlaces: createVlPlacesModule(options),
    vlPeriods: createVlPeriodsModule(options),
    vlRegions: createVlRegionsModule(options),
    vlTickets: createVlTicketsModule(options),
    vlVendors: createVlVendorsModule(options),
    vlProducts: createVlProductsModule(options),
    vlCampaigns: createVlCampaignsModule(options),
    vlSalesMans: createVlSalesMansModule(options),
    vlPlaceTypes: createVlPlaceTypesModule(options),
    vlAdmEntities: createVlAdmEntitiesModule(options),
    vlPeriodProducts: createVlPeriodProductsModule(options),
    collectionFilter: createCollectionFilterModule(options),
    vlSelectorPlaces: createVlSelectorPlacesModule(options),
    vlSelectorVendors: createVlSelectorVendorsModule(options),
    vlCampaignProducts: createVlCampaignProductsModule(options),
    vlRegionalManagers: createVlRegionalManagersModule(options),
    vlDupUsersForRoute: createVlDupUsersForRouteModule(options),
    vlSelectorSalesMans: createVlSelectorSalesMansModule(options),
    vlDupPlacesForRoute: createVlDupPlacesForRouteModule(options),
    vlSelectorRegionalManagers: createVlSelectorRegionalManagersModule(options),
  },
});
