<template>

  <!-- Pagination -->
  <b-pagination-nav
    :value="page"
    align="center"
    v-if="pages > 1"
    class="page-pagination"
    :number-of-pages="pages"
    :link-gen="v => `#page-${v}`"
    @input="$emit('input', $event)">

    <template
      slot="next-text">

      <i
        class="simple-icon-arrow-right"/>

    </template>

    <template
      slot="prev-text">

      <i
        class="simple-icon-arrow-left"/>

    </template>

    <template
      slot="first-text">

      <i
        class="simple-icon-control-start"/>

    </template>

    <template
      slot="last-text">

      <i
        class="simple-icon-control-end"/>

    </template>

  </b-pagination-nav>

</template>

<script>

  export default {
    name: 'page-pagination',
    props: {
      page: {
        type: Number,
        required: true,
      },
      pages: {
        type: Number,
        required: true,
      },
    },
  };

</script>

<style lang="scss">

  .page-pagination {
    color: inherit;
  }

</style>
