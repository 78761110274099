
/**
 * Ticket statuses.
 *
 * @param state
 * @returns {{count: *, status: *}[]}
 */
export function ticketStatuses(state) {
  return Object.keys(state.counter).map(statusName => ({
    statusName,
    ticketsNumber: state.counter[statusName],
  }));
}
