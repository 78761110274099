
export default function () {

  return {
    // item: null,
    // tempItem: null,
    // items: [],
    // skip: 0,
    limit: 12,
    // total: 0,
    orderBy: 'id',
    orderDesc: true,
    searchBy: ['title'],
    // searchQuery: '',
    // where: {},
    // loading: false,
    // include: [],
    // fields: [],
  };
}
