import initialState from './state';

export function SET_LOADING(state, value) {

  state.loading = !!value;
}

export function RESET_LOADING(state) {

  state.loading = initialState().loading;
}

export function SET_ITEMS(state, value) {

  state.items = value;
}

export function RESET_ITEMS(state) {

  state.items = initialState().items;
}

export function SET_TOTAL(state, value) {

  state.total = value;
}

export function RESET_TOTAL(state) {

  state.total = initialState().total;
}

export function SET_LIMIT(state, value) {

  state.limit = value;
}

export function RESET_LIMIT(state) {

  state.limit = initialState().limit;
}

export function SET_OFFSET(state, value) {

  state.offset = value;
}

export function RESET_OFFSET(state) {

  state.offset = initialState().offset;
}

export function SET_SEARCH(state, value) {

  state.search = value;
}

export function RESET_SEARCH(state) {

  state.search = initialState().search;
}

export function SET_ORDER(state, value) {

  state.order = value;
}

export function RESET_ORDER(state) {

  state.order = initialState().order;
}

export function SET_CITY_ID(state, value) {

  state.cityId = value;
}

export function RESET_CITY_ID(state) {

  state.cityId = initialState().cityId;
}

export function SET_ITEM(state, value) {

  state.item = value;
}

export function RESET_ITEM(state) {

  state.item = initialState().item;
}

export function SET_TEMP(state, value) {

  state.temp = value;
}

export function RESET_TEMP(state) {

  state.temp = initialState().temp;
}

export function SET_ITEM_LOADING(state, value) {

  state.itemLoading = value;
}

export function RESET_ITEM_LOADING(state) {

  state.itemLoading = initialState().itemLoading;
}

export function SET_INVITE_PHONE(state, value) {

  state.invitePhone = value;
}

export function RESET_INVITE_PHONE(state) {

  state.invitePhone = initialState().invitePhone;
}
