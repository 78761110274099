<template>

  <!-- Page Container -->
  <div
    :class="classes"
    class="page-container">

    <!-- Header -->
    <div
      class="page-container__header">

      <slot
        name="header"/>

    </div>

    <!-- Wrapper -->
    <div
      class="page-container__wrapper">

      <!-- Content -->
      <div
        :class="contentClasses"
        class="page-container__content">

        <slot/>

      </div>

    </div>

  </div>

</template>

<script>

  export default {
    name: 'page-container',
    props: {
      color: {
        type: String,
        default: 'platinum',
      },
      fill: {
        type: Boolean,
        default: false,
      },
      size: {
        type: [String, Number],
        default: 0,
      },
      dense: {
        type: Boolean,
        default: false,
      },
      noBody: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      classes() {

        return {
          fill: this.fill,
          [this.color]: this.color,
          'outside-toolbar': this.outsideToolbar,
        };
      },
      contentClasses() {

        let padding = this.noBody
          ? ''
          : 'container_pa';

        padding += this.dense
          ? '_dense'
          : '';

        return [
          padding,
          'container_centered',
          `container_mw-${this.size}`,
        ];
      },
    },
  };

</script>

<style lang="scss">

  .page-container {
    overflow: hidden;

    &.fill {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      position: absolute;
      flex-direction: column;
    }

    &__header {
      flex-shrink: 0;
    }

    &__wrapper {
      flex-basis: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      position: relative;
    }

    &__content {
      min-height: 100%;
    }
  }

</style>
