import {messageFromError} from '@/utils/index.js';

/**
 * Add place to user.
 *
 * @param state
 * @param commit
 * @param payload
 * @param collection
 * @returns {Promise<boolean>}
 * @constructor
 */
export async function addPlaceToUser(state, commit, payload, collection) {

  commit('SET_LOADING', true);

  const body = {
    userId: payload.userId,
    placeId: payload.placeId,
  }
  const filter = {
    include: state.include,
    fields: state.fields,
  }
  let response;

  try {
    response = await this.$api.post(
      `/${collection}/addPlace?filter=${JSON.stringify(filter)}`,
      body,
    );
  } catch (error) {
    this.$notify(
      'error',
      'Ошибка',
      messageFromError(error),
      {duration: 3000, permanent: false},
    );
    return false;
  } finally {
    commit('RESET_LOADING');
  }

  // add user
  if (typeof response.data === 'object') {
    commit('SET_ITEMS', [response.data, ...state.items]);
    commit('SET_TOTAL', state.total +1);
    this.$notify(
      'success',
      `Пользователь добавлен`,
      undefined,
      {duration: 3000, permanent: false},
    );
    return true;
  }

  // no user
  this.$notify(
    'error',
    'Ошибка',
    'Что-то пошло не так',
    {duration: 3000, permanent: false},
  );
  return false;
}

/**
 * Remove place from user.
 *
 * @param state
 * @param commit
 * @param payload
 * @param collection
 * @returns {Promise<boolean>}
 * @constructor
 */
export async function removePlaceFromUser(state, commit, payload, collection) {

  commit('SET_LOADING', true);

  const body = {
    userId: payload.userId,
    placeId: payload.placeId,
  }
  const filter = {
    include: state.include,
    fields: state.fields,
  }

  try {
    await this.$api.post(
      `/${collection}/removePlace?filter=${JSON.stringify(filter)}`,
      body,
    );
  } catch (error) {
    this.$notify(
      'error',
      'Ошибка',
      messageFromError(error),
      {duration: 3000, permanent: false},
    );
    return false;
  } finally {
    commit('RESET_LOADING');
  }

  // remove user
  const users = state.items.filter(v => v.id !== payload.userId);
  commit('SET_ITEMS', users);
  commit('SET_TOTAL', state.total -1);
  this.$notify(
    'success',
    'Пользователь исключен',
    undefined,
    {duration: 3000, permanent: false},
  );
  return true;
}
