import Vue from 'vue';
import router from '@/router';
import api from '@/clients/api';

export async function LOAD_ITEMS({commit, state}) {

  commit('SET_LOADING', true);

  const options = {
    total:  true,
    order:  state.order,
    offset: state.offset,
    limit:  state.limit,
    search: state.search,
  };

  const response = await api.get(
    `/regions/find?filter=${JSON.stringify(options)}`,
  ).catch((error) => {
    console.log(error);
    commit('RESET_LOADING');

    Vue.prototype.$notify(
      'error',
      'Ошибка загрузки',
      error.message,
      {duration: 3000, permanent: false},
    );
  });

  if (response) {

    commit('SET_ITEMS', response.data.items);
    commit('SET_TOTAL', response.data.total);
    commit('RESET_LOADING');

    return response.data;
  }
}

export function CHANGE_PAGE({state, commit, dispatch}, payload) {

  commit(
    'SET_OFFSET',
    state.limit * (payload - 1),
  );

  return dispatch('LOAD_ITEMS');
}

export function CHANGE_PAGE_SIZE({commit, dispatch}, payload) {

  commit('SET_LIMIT', payload);
  commit('RESET_OFFSET');
  return dispatch('LOAD_ITEMS');
}

export function CHANGE_ORDER({commit, dispatch}, payload) {

  commit('SET_ORDER', payload);
  return dispatch('LOAD_ITEMS');
}

export function SEARCH_ITEMS({commit, dispatch}, payload) {

  commit('SET_SEARCH', payload);
  commit('SET_OFFSET', 0);
  return dispatch('LOAD_ITEMS');
}

export async function LOAD_ITEM({commit, state}, id) {

  commit('SET_ITEM_LOADING', true);

  const response = await api.get(
    `/regions/find?filter=${JSON.stringify({id})}`,
  ).catch((error) => {
    console.log(error);
    commit('RESET_ITEM_LOADING');
    router.push('/404');
  });

  if (response) {

    const item = response.data.length
      ? response.data[0]
      : null;

    commit('SET_ITEM', item);
    commit('RESET_ITEM_LOADING');

    if (!item)
      // router.push('/404');

    return item;
  }
}
