import _ from 'lodash';

/**
 * Get User Name
 *
 * @param  {object} user
 * @param  {string} placeholder
 * @return {string}
 */
export function getUserName(
  user,
  placeholder = 'Без имени',
) {

  if (_.isObject(user)) {

    const firstName = user.name || '';
    const lastName = user.surname || '';

    return (firstName + ' ' + lastName).trim()
      || placeholder;
  }

  return '';
}

export function humanStatus(user) {

  if (!user.phoneVerified)
    return {label: 'приглашен', color: 'info'};

  if (user.blocked)
    return {label: 'заблокирован', color: 'secondary'};

  return {label: 'активный', color: 'outline-primary'};
}

/**
 * Get First Role
 *
 * @param  {object} user
 * @param  {object} placeholder
 * @return {string|object}
 */
export function getRoleName(
  user,
  placeholder = null,
) {
  if (
    _.isObject(user) &&
    _.isObject(user.role) &&
    user.role.name
  ) return user.role.name;

  return placeholder;
}

/**
 * Get user score.
 *
 * @param user
 * @param placeholder
 * @returns {number|*|number}
 */
export function getUserScore(user, placeholder = 0) {
  if (typeof user === 'object') {
    const pending = user.pendingScore || 0;
    const score = user.score || 0;
    return (pending + score) || placeholder;
  }
  return placeholder;
}
