
export default function () {

  const sessionUser = localStorage.getItem('user') != null
    ? JSON.parse(localStorage.getItem('user'))
    : null;

  return {
    loading: false,
    error: null,
    sessionUser,
  };
}
