var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "position-absolute card-top-buttons" }, [
    _c(
      "button",
      {
        staticClass: "btn btn-header-light icon-button",
        on: {
          click: function ($event) {
            return _vm.$emit("click")
          },
        },
      },
      [_c("i", { staticClass: "simple-icon-refresh" })]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }