import initialState from './state';

export function RESET(state) {

  Object.assign(
    state,
    initialState()
  );
}

export function SET_QUERY(state, value) {

  state.query = value || '';
}

export function RESET_QUERY(state) {

  state.query = initialState().query;
}

export function SET_ROLE(state, value) {

  state.role = value || null;
}

export function RESET_ROLE(state) {

  state.role = initialState().role;
}

export function SET_CITY(state, value) {

  state.city = value || null;
}

export function RESET_CITY(state) {

  state.city = initialState().city;
}

export function SET_PLACE(state, value) {

  state.place = value || null;
}

export function RESET_PLACE(state) {

  state.place = initialState().place;
}

export function SET_AGENCY(state, value) {

  state.agency = value || null;
}

export function RESET_AGENCY(state) {

  state.agency = initialState().agency;
}

export function SET_CAMPAIGN(state, value) {

  state.campaign = value || null;
}

export function RESET_CAMPAIGN(state) {

  state.campaign = initialState().campaign;
}

export function SET_INVITED(state, value) {

  state.invited = value;
}

export function RESET_INVITED(state) {

  state.invited = initialState().invited;
}

export function SET_DATE(state, value) {

  state.date = value || null;
}

export function RESET_DATE(state) {

  state.date = initialState().date;
}

export function SET_CREATEDAT(state, value) {

  state.createdAt = value || null;
}

export function RESET_CREATEDAT(state) {

  state.createdAt = initialState().createdAt;
}

export function SET_TICKETSTATUS(state, value) {

  state.ticketStatus = value || '';
}

export function RESET_TICKETSTATUS(state) {

  state.ticketStatus = initialState().ticketStatus;
}

export function SET_ONLINESTATUS(state, value) {

  state.onlineStatus = value;
}

export function RESET_ONLINESTATUS(state) {

  state.onlineStatus = initialState().onlineStatus;
}
