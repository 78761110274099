import createState from '../vl-sales-mans/state';
import * as getters from '../vl-sales-mans/getters';
import * as actions from '../vl-sales-mans/actions';
import * as mutations from '../vl-sales-mans/mutations';

import {createModule} from 'vuex-loopback';
import model from '@/models/user-model';

export default function ({client, onError, onSuccess}) {

  return {
    namespaced: true,
    ...createModule({
      model,
      client,
      onError,
      onSuccess,
      collection: 'salesMans',
      getters,
      actions,
      mutations,
      state: createState(),
    }),
  };
}
