import initialState from './state';

export function SET_USERS(state, value) {

  state.users = value || [];
}

export function RESET_USERS(state) {

  state.users = initialState().users;
}

export function SET_CITIES(state, value) {

  state.cities = value || [];
}

export function RESET_CITIES(state) {

  state.cities = initialState().cities;
}

export function SET_LOADING(state, value) {

  state.loading = value;
}

export function RESET_LOADING(state) {

  state.loading = initialState().loading;
}

export function SET_SELECTED_PLACE(state, value) {

  state.selectedPlace = value;
}

export function RESET_SELECTED_PLACE(state) {

  state.selectedPlace = initialState().selectedPlace;
}

export function SET_SELECTED_USER(state, value) {

  state.selectedUser = value;
}

export function RESET_SELECTED_USER(state) {

  state.selectedUser = initialState().selectedUser;
}

export function SET_INVITE_MODAL(state, value) {

  state.inviteModal = value || '';
}

export function RESET_INVITE_MODAL(state) {

  state.inviteModal = initialState().inviteModal;
}

export function SET_INVITE_PHONE(state, value) {

  state.invitePhone = value || '';
}

export function RESET_INVITE_PHONE(state) {

  state.invitePhone = initialState().invitePhone;
}

export function SET_INVITE_PLACE(state, value) {

  state.invitePlace = value || null;
}

export function RESET_INVITE_PLACE(state) {

  state.invitePlace = initialState().invitePlace;
}

export function SET_REMOVAL_USER(state, value) {

  state.removalUser = value;
}

export function RESET_REMOVAL_USER(state) {

  state.removalUser = initialState().removalUser;
}

export function SET_REMOVAL_MODAL(state, value) {

  state.removalModal = !!value;
}

export function RESET_REMOVAL_MODAL(state) {

  state.removalModal = initialState().removalModal;
}
