/**
 * Humanize price.
 *
 * @param input
 * @param isKopecks
 * @returns {string|number|*|string}
 */
module.exports.humanizePrice = function(input, isKopecks = false) {
  if (typeof input !== 'string' && typeof input !== 'number') {
    return input;
  }
  if (isKopecks) input = Number(input) / 100;
  if (typeof input === 'number' && !isNaN(input)) {
    return input.toFixed(2);
  }
  if (typeof input === 'string' && /^[\d.]+$/.test(input)) {
    const value = parseFloat(input);
    if (isNaN(value)) return input;
    return value.toFixed(2);
  }
  return input;
}
