
export default function () {

  return {
    items: [],
    loading: false,
    total: 0,
    limit: 12,
    offset: 0,
    search: '',
    order: 'dateTime DESC', // "new" or "noStatus"
    item: null,
    tempItem: null,
    itemLoading: false,
    itemDetails: null,
    userId: '',
  };
}
