import api from './api.js';
import utils from './utils.js';
import lodash from './lodash.js';
import notify from './notify.js';
import socket from './socket.js';
import router from './router.js';
import cookies from './cookies.js';
import confirm from './confirm.js';
import getParent from './get-parent.js';
import components from './components.js';
import resetState from './reset-state.js';
import routerGuard from './router-guard.js';

export default [
  api,
  utils,
  notify,
  lodash,
  router,
  socket,
  cookies,
  confirm,
  getParent,
  components,
  resetState,
  routerGuard,
];
