import Vue from 'vue';
import {messageFromError} from '@/utils/index.js';

/**
 * Scroll history container to end.
 *
 * @param force
 */
function scrollHistoryContainerToEnd(force = false) {
  Vue.nextTick(() => {
    const els = window.document
      .getElementsByClassName('ticket-auto-processor-editor__history');
    if (!els.length) return;
    const el = els[0];
    if (force || el.scrollHeight - el.clientHeight - el.scrollTop < 70)
      el.scrollTo(0, el.scrollHeight);
  });
}

/**
 * Join.
 *
 * @param state
 * @param commit
 * @returns {*}
 * @constructor
 */
export function JOIN({state, commit}) {
  commit('RESET');
  const setStatus = status => {
    commit('SET_STATUS', status);
    if (status !== 'processing')
      commit('RESET_STOP_REQUESTED');
  };
  const setHistory = history => {
    commit('SET_HISTORY', history);
    scrollHistoryContainerToEnd(true);
  };
  const addHistoryRecord = rec => {
    commit('ADD_HISTORY_RECORD', rec);
    scrollHistoryContainerToEnd();
  };
  const setTicketCounter = counter => commit('SET_COUNTER', counter);
  this.$socket.io.on('ticketAutoProcessor.status', setStatus);
  this.$socket.io.on('ticketAutoProcessor.history', setHistory);
  this.$socket.io.on('ticketAutoProcessor.historyRecord', addHistoryRecord);
  this.$socket.io.on('ticketAutoProcessor.ticketCounter', setTicketCounter);
  this.$socket.join('ticket-auto-processor');
  return state;
}

/**
 * Leave.
 *
 * @param state
 * @param commit
 * @returns {*}
 * @constructor
 */
export function LEAVE({state, commit}) {
  this.$socket.leave('ticket-auto-processor');
  this.$socket.io.off('ticketAutoProcessor.status');
  this.$socket.io.off('ticketAutoProcessor.history');
  this.$socket.io.off('ticketAutoProcessor.historyRecord');
  this.$socket.io.off('ticketAutoProcessor.ticketCounter');
  commit('RESET');
  return state;
}

/**
 * Start.
 *
 * @param state
 * @param commit
 * @returns {Promise<*>}
 * @constructor
 */
export async function START({state, commit}) {
  if (!this.$socket.io.connected)
    return this.$notify(
      'error',
      'Ошибка',
      'Нет подключения к обработчику чеков.',
      {duration: 3000, permanent: false},
    );
  commit('SET_LOADING', true);
  commit('RESET_COUNTER');
  try {
    await this.$api.get(
      '/tickets/startAutoProcessing',
    );
  } catch (error) {
    this.$notify(
      'error',
      'Ошибка',
      messageFromError(error),
      {duration: 3000, permanent: false},
    );
  }
  commit('RESET_LOADING');
  return state;
}

/**
 * Stop.
 *
 * @param state
 * @param commit
 * @returns {Promise<*>}
 * @constructor
 */
export async function STOP({state, commit}) {
  if (!this.$socket.io.connected)
    return this.$notify(
      'error',
      'Ошибка',
      'Нет подключения к обработчику чеков.',
      {duration: 3000, permanent: false},
    );
  commit('SET_LOADING', true);
  commit('SET_STOP_REQUESTED', true);
  try {
    await this.$api.get(
      '/tickets/stopAutoProcessing',
    );
  } catch (error) {
    this.$notify(
      'error',
      'Ошибка',
      messageFromError(error),
      {duration: 3000, permanent: false},
    );
  }
  commit('RESET_LOADING');
  return state;
}
