import {messageFromError} from '@/utils/index.js';
import {addPlaceToUser} from '@/store/actions/index.js';
import {removePlaceFromUser} from '@/store/actions/index.js';

/**
 * Invite user.
 *
 * @param state
 * @param commit
 * @param rootGetters
 * @param payload
 * @returns {Promise<boolean>}
 * @constructor
 */
export async function INVITE({state, commit, rootGetters}, payload) {

  commit('SET_LOADING', true);

  const body = {
    name: payload.name.trim(),
    surname: payload.surname.trim(),
    phone: payload.phone.trim().replace(/^\+/, ''),
    inviterId: payload.inviterId,
    sendCode: payload.sendCode,
  }
  const filter = {
    include: state.include,
    fields: state.fields,
  }
  let response;

  try {
    response = await this.$api.post(
      `/salesMans/invite?filter=${JSON.stringify(filter)}`,
      body,
    );
  } catch (error) {
    this.$notify(
      'error',
      'Ошибка',
      messageFromError(error),
      {duration: 3000, permanent: false},
    );
    return false;
  } finally {
    commit('RESET_LOADING');
  }

  // add user
  if (
    typeof response.data === 'object' &&
    typeof response.data.user === 'object'
  ) {
    commit('SET_ITEMS', [response.data.user, ...state.items]);
    this.$notify(
      'success',
      `Код ${response.data.code}`,
      'Торговый представитель приглашен',
      {permanent: true},
    );
    return true;
  }

  // no user
  this.$notify(
    'error',
    'Ошибка',
    'Что-то пошло не так',
    {duration: 3000, permanent: false},
  );
  return false;
}

/**
 * Add place.
 *
 * @param state
 * @param commit
 * @param payload
 * @returns {Promise<boolean>}
 * @constructor
 */
export async function ADD_PLACE({state, commit}, payload) {
  return addPlaceToUser.call(this, state, commit, payload, 'salesMans');
}

/**
 * Remove place.
 *
 * @param state
 * @param commit
 * @param rootGetters
 * @param payload
 * @returns {Promise<boolean>}
 * @constructor
 */
export async function REMOVE_PLACE({state, commit, rootGetters}, payload) {
  return removePlaceFromUser.call(this, state, commit, payload, 'salesMans');
}
