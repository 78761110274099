var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-form-input", {
    staticClass: "time-picker",
    attrs: { type: "time", value: _vm.time },
    on: { input: _vm.input },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }