import Vue from 'vue';
import router from '@/router';
import api from '@/clients/api';
import {messageFromError} from '@/utils/api';

export async function LOGIN({commit}, {login, password}) {

  commit('RESET_ERROR');
  commit('SET_LOADING', true);

  const response = await api.post(
    '/users/login',
    {phone: login.replace(/\+/, ''), password},
  ).catch((error) => {
    console.log(error);
    localStorage.removeItem('user');
    commit('SET_ERROR', error.message);
    commit('RESET_SESSION_USER');
    commit('RESET_LOADING');
  });

  if (response) {

    localStorage.setItem('user', JSON.stringify(response.data));
    commit('SET_SESSION_USER', response.data);
    commit('RESET_LOADING');

    return response.data;
  }
}

export function LOGOUT({commit}) {

  localStorage.removeItem('user');
  commit('RESET_SESSION_USER');
  router.push('/user/login');
}

export async function INVITE_TO_PLACE({commit, state}, {phone, placeId, role} = {}) {

  if (!phone || !placeId || !role)
    return;

  commit('SET_LOADING', true);

  let response;

  try {

    response = await api.post('/users/invite', {
      phone: phone.replace('+', ''),
      roleName: role,
      placeId,
    });

  } catch (error) {

    Vue.prototype.$notify(
      'error',
      'Ошибка приглашения.',
      messageFromError(error),
      {duration: 3000, permanent: false},
    );
  }

  if (response) {

    commit('RESET_LOADING');

    Vue.prototype.$notify(
      'success',
      'Приглашение отправлено.',
      '',
      {duration: 3000, permanent: false},
    );

    return response.data;
  }

  commit('RESET_LOADING');
}

export async function SET_PLACE({commit, state}, {userId, placeId} = {}) {

  if (!userId || !placeId)
    return;

  commit('SET_LOADING', true);

  let response;

  try {

    response = await api.get(
      `/users/setUserPlace/${userId}/${placeId}`,
    );

  } catch (error) {

    Vue.prototype.$notify(
      'error',
      'Ошибка назначения.',
      messageFromError(error),
      {duration: 3000, permanent: false},
    );
  }

  if (response) {

    commit('RESET_LOADING');

    Vue.prototype.$notify(
      'success',
      'Успешно назначено.',
      '',
      {duration: 3000, permanent: false},
    );

    return response.data;
  }

  commit('RESET_LOADING');
}

export async function RESET_PLACE({commit, state}, userId) {

  if (!userId)
    return;

  commit('SET_LOADING', true);

  let response;

  try {

    response = await api.get(
      `/users/resetUserPlace/${userId}`,
    );

  } catch (error) {

    Vue.prototype.$notify(
      'error',
      'Ошибка назначения.',
      messageFromError(error),
      {duration: 3000, permanent: false},
    );
  }

  if (response) {

    commit('RESET_LOADING');

    Vue.prototype.$notify(
      'success',
      'Успешно снято.',
      '',
      {duration: 3000, permanent: false},
    );

    return response.data;
  }

  commit('RESET_LOADING');
}
