<template>

  <!-- Date Picker (v-calendar) -->
  <v-date-picker
    mode="single"
    :value="date"
    @input="input"
    class="date-picker"
    :class="{'no-pointer-events': !!disabled}">

    <template
      v-slot="{inputValue, updateValue}">

      <b-form-input
        type="date"
        :value="inputValue"
        @input="updateValue"
        class="form-control"
        :readonly="Boolean(disabled)"/>

    </template>

  </v-date-picker>

</template>

<script>

  export default {
    name: 'date-picker',
    props: [
      'value',
      'disabled',
    ],
    computed: {
      date() {

        if (!this.value)
          return undefined;

        return new Date(
          this.$moment
            .parseZone(this.value)
            .format('YYYY-MM-DD')
        );
      },
    },
    methods: {
      input(value) {

        let iso = null;

        if (value) {

          const date = this.$moment
            .parseZone(value)
            .format('YYYY-MM-DD');

          const time = this.$moment
            .parseZone(this.value || value)
            .format('HH:mm:ss.SSS');

          iso = `${date}T${time}Z`;
        }

        this.$emit('input', iso);
      },
    },
  };

</script>

<style lang="scss">

  .date-picker {
    flex: 1;
    padding: 0 !important;
    border: none !important;

    input {
      min-height: 100%;
    }
  }

</style>
