
export default function () {
  return {
    inputText: '',
    messages: [],
    messagesRoomName: null,
    messagesLimit: 12,
    messagesLoading: false,
    messagesHasMore: null,
    messagesFirstDate: null,
    convs: [],
    convsLimit: 24,
    convsHasMore: null,
    convsLoading: false,
    convsTotal: 0,
  };
}
