
export default function () {

  return {
    users: [],
    cities: [],
    loading: false,
    selectedPlace: null,
    selectedUser: null,
    invitePhone: '+7',
    invitePlace: null,
    inviteModal: false,
    removalUser: null,
    removalModal: false,
  };
}
