import initialState from './state';

export function SET_FILTER(state, value) {

  state.filter = value || '';
}

export function RESET_FILTER(state) {

  state.filter = initialState().filter;
}
