var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-date-picker", {
    staticClass: "date-picker",
    class: { "no-pointer-events": !!_vm.disabled },
    attrs: { mode: "single", value: _vm.date },
    on: { input: _vm.input },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ inputValue, updateValue }) {
          return [
            _c("b-form-input", {
              staticClass: "form-control",
              attrs: {
                type: "date",
                value: inputValue,
                readonly: Boolean(_vm.disabled),
              },
              on: { input: updateValue },
            }),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }