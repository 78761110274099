import Vue from 'vue';
import {store} from './store';
import Router from 'vue-router';

Vue.use(Router);

const routes = [
  {
    path: '/',
    redirect: '/app',
  },
  {
    path: '/app',
    redirect: '/app/profile',
    component: () => import('./views/app'),
    meta: {
      requiredRoles: [
        '$authenticated',
      ],
    },
    children: [
      {
        name: 'app-profile',
        path: 'profile',
        component: () => import('./views/app/profile'),
      },
      {
        name: 'app-messenger',
        path: 'messenger',
        component: () => import('./views/app/messenger'),
      },
      {
        name: 'app-tickets',
        path: 'tickets',
        component: () => import('./views/app/tickets'),
      },
      {
        name: 'app-ticket',
        path: 'ticket/:id',
        component: () => import('./views/app/ticket.vue'),
      },
      {
        name: 'app-regional-managers',
        path: 'regional-managers',
        component: () => import('./views/app/regional-managers.vue'),
      },
      {
        name: 'app-regional-manager',
        path: 'regional-manager/:id',
        component: () => import('./views/app/regional-manager.vue'),
      },
      {
        name: 'app-regional-manager-place',
        path: 'regional-manager/:userId/place/:placeId',
        component: () => import('./views/app/user-place.vue'),
        props: true,
      },
      {
        name: 'app-sales-mans',
        path: 'sales-mans',
        component: () => import('./views/app/sales-mans.vue'),
      },
      {
        name: 'app-sales-man',
        path: 'sales-man/:id',
        component: () => import('./views/app/sales-man.vue'),
      },
      {
        name: 'app-sales-man-place',
        path: 'sales-man/:userId/place/:placeId',
        component: () => import('./views/app/user-place.vue'),
        props: true,
      },
      {
        name: 'app-vendors',
        path: 'vendors',
        component: () => import('./views/app/vendors.vue'),
      },
      {
        name: 'app-vendor',
        path: 'vendor/:id',
        component: () => import('./views/app/vendor.vue'),
      },
      {
        name: 'app-vendor-place',
        path: 'vendor/:userId/place/:placeId',
        component: () => import('./views/app/user-place.vue'),
        props: true,
      },
      {
        name: 'app-vendor-ticket',
        path: 'vendor/:userId/ticket/:ticketId',
        component: () => import('./views/app/vendor-ticket.vue'),
        props: true,
      },
      {
        name: 'app-places',
        path: 'places',
        component: () => import('./views/app/places'),
      },
      {
        name: 'app-place',
        path: 'place/:id?',
        component: () => import('./views/app/place'),
      },
      {
        name: 'app-place-vendor',
        path: 'place/:placeId/vendor/:vendorId',
        component: () => import('./views/app/place-vendor'),
        props: true,
      },
      {
        name: 'app-place-sales-man',
        path: 'place/:placeId/sales-man/:salesManId',
        component: () => import('./views/app/place-sales-man'),
        props: true,
      },
      {
        name: 'app-place-regional-manager',
        path: 'place/:placeId/regional-manager/:regionalManagerId',
        component: () => import('./views/app/place-regional-manager'),
        props: true,
      },
      {
        name: 'app-place-ticket',
        path: 'place/:placeId/ticket/:ticketId',
        component: () => import('./views/app/place-ticket'),
        props: true,
      },
      {
        name: 'app-cities',
        path: 'cities',
        component: () => import('./views/app/cities'),
      },
      {
        name: 'app-city',
        path: 'city/:id?',
        component: () => import('./views/app/city'),
      },
      {
        name: 'app-adm-entities',
        path: 'adm-entities',
        component: () => import('./views/app/adm-entities'),
      },
      {
        name: 'app-adm-entity',
        path: 'adm-entity/:id?',
        component: () => import('./views/app/adm-entity'),
      },
      {
        name: 'app-regions',
        path: 'regions',
        component: () => import('./views/app/regions'),
      },
      {
        name: 'app-region',
        path: 'region/:id?',
        component: () => import('./views/app/region'),
      },
      {
        name: 'app-campaigns',
        path: 'campaigns',
        component: () => import('./views/app/campaigns'),
      },
      {
        name: 'app-campaign',
        path: 'campaign/:id?',
        component: () => import('./views/app/campaign'),
      },
      {
        name: 'app-campaign-period',
        path: 'campaign/:campaignId/period/:periodId?',
        component: () => import('./views/app/campaign-period.vue'),
        props: true,
      },
      {
        name: 'app-reports',
        path: 'reports',
        component: () => import('./views/app/reports.vue'),
      },
      {
        name: 'app-products',
        path: 'products',
        component: () => import('./views/app/products.vue'),
      },
      {
        name: 'app-product',
        path: 'product/:id?',
        component: () => import('./views/app/product.vue'),
      },
    ],
  },
  {
    path: '/user',
    redirect: '/user/login',
    component: () => import('./views/user'),
    children: [
      {
        name: 'user-login',
        path: 'login',
        component: () => import('./views/user/login')
      },
      {
        name: 'user-remind',
        path: 'remind',
        component: () => import('./views/user/remind'),
      },
    ],
  },
  {
    name: 'error',
    path: '/error/:code?',
    component: () => import('./views/error'),
    props: true,
  },
  {
    path: '*',
    component: () => import('./views/error'),
  },
];

const router = new Router({
  linkActiveClass: 'active',
  routes,
  mode: 'history',
});

router.beforeEach((to, from, next) => {

  store.commit('messages/RESET_INPUT');

  if (from && to && (from.path !== to.path)) {

    store.dispatch('places/RESET');
    store.dispatch('cities/RESET');
    store.dispatch('vendors/RESET');
    store.dispatch('vendorPlaceEditor/RESET');
  }

  next();
});

export default router;
