
export function totalPages(state) {

  return Math.ceil(state.total / state.limit);
}

export function page(state) {

  return Math.floor(state.offset / state.limit) + 1;
}

export function orderOptions(state) {

  return [
    {value: 'tickets', label: 'новый чек'},
    {value: 'messages', label: 'новое сообщение'},
  ];
}

export function orderOption(state, getters) {

  return getters.orderOptions.find(
    item => item.value === state.order,
  );
}
