import moment from 'moment/moment';

/**
 * ISO Date.
 *
 * @param {object} options
 * @return {string}
 */
export function isoDate({
  from,
  local,
  startOf,
  endOf,
} = {}) {

  let date = moment.parseZone(
    from || undefined,
  );

  let offset = 'Z';

  if (local)
    offset = moment.utc().utcOffset(
      new Date().getTimezoneOffset(),
    ).format('Z');

  date = moment.utc(
    date.format(
      'YYYY-MM-DDTHH:mm:ss.SSS'
    ) + offset,
  );

  if (startOf) date = date.startOf(startOf);
  if (endOf) date = date.endOf(endOf);

  return date.toISOString();
}

/**
 * Is Same Date.
 *
 * @param first
 * @param second
 * @param options
 * @return {boolean}
 */
export function isSameDate(
  first,
  second,
  {unit = 'day'} = {},
) {

  first = moment.utc(first);
  second = moment.utc(second);

  return first.isSame(second, unit)
}

/**
 * Human Date.
 *
 * @param date
 * @param placeholder
 * @param short
 * @return {string}
 */
export function humanDate(
  date,
  placeholder = '',
  short = false,
) {
  if (!date)
    return placeholder;

  const pattern = short
    ? 'D MMMM'
    : 'D MMMM YYYY';

  return moment
    .utc(date)
    .format(pattern);
}

/**
 * Human Time.
 *
 * @param time
 * @param placeholder
 * @return {string}
 */
export function humanTime(
  time,
  placeholder = '',
) {

  if (!time)
    return placeholder;

  return moment
    .utc(time)
    .format('HH:mm');
}

/**
 * Human Date Range.
 *
 * @param first
 * @param second
 * @param placeholder
 * @return {string}
 */
export function humanDateRange(
  first,
  second,
  placeholder = '',
  options = {},
) {

  if (!first)
    return placeholder;

  const sameDay = isSameDate(
    first,
    second,
  );

  if (!second || sameDay)
    return moment
      .utc(first)
      .format('D MMMM YYYY');

  first = moment
    .utc(first)
    .format('D MMM');

  second = moment
    .utc(second)
    .format('D MMM YYYY');

  return `${first} - ${second}`;
}

/**
 * Human Date Time.
 *
 * @param date
 * @param placeholder
 * @param format
 * @return {string}
 */
export function humanDateTime(
  date,
  placeholder = '',
  format = 'D MMMM HH:mm',
) {

  if (!date)
    return placeholder;

  return moment
    .utc(date)
    .format(format);
}

/**
 * Human Duration.
 *
 * @param value
 * @param placeholder
 * @param short
 * @return {string}
 */
export function humanDuration(
  value,
  placeholder = '',
  short = false,
) {

  if (!isDuration(value))
    return placeholder;

  if (short)
    return moment.utc(value * 1000)
      .format('H:mm');

  return moment
    .duration(value, 'seconds')
    .format('h [чac.] m [мин]', {trim: 'both'}) + '.';
}

export function isDuration(value) {

  return value && value < 86400;
}

export function periodDateHtml(period) {
  let first = period.startDate;
  let second = period.endDate;
  const placeholder = 'Без даты';

  if (!first) return placeholder;
  const sameDay = isSameDate(first, second);
  if (!second || sameDay) return moment.utc(first).format('D MMM');
  first = moment.utc(first).format('D MMMM YYYY');
  second = moment.utc(second).format('D MMMM YYYY');
  return `${first} <small>по</small> ${second}`;
}
