const state = {
  menuType: 'menu-default',
}

const getters = {
  getMenuType(state) {
    return state.menuType
  },
}

const mutations = {
  setMenuType(state, value) {
    state.menuType = value || '';
  },
  toggleMenu(state) {
    let nextClass = state.menuType;
    if (nextClass.includes('main-hidden')) {
      nextClass = nextClass.replace(/(^|\s+)(main-hidden)(\s+|$)/g, '$3');
    } else {
      nextClass += ' main-hidden';
    }
    state.menuType = nextClass;
  },
}

const actions = {}

export default {
  state,
  getters,
  mutations,
  actions
}
