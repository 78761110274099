import createState from '../vl-regional-managers/state';
import * as getters from '../vl-regional-managers/getters';
import * as actions from '../vl-regional-managers/actions';
import * as mutations from '../vl-regional-managers/mutations';

import {createModule} from 'vuex-loopback';
import model from '@/models/user-model';

export default function ({client, onError, onSuccess}) {

  return {
    namespaced: true,
    ...createModule({
      model,
      client,
      onError,
      onSuccess,
      collection: 'regionalManagers',
      getters,
      actions,
      mutations,
      state: createState(),
    }),
  };
}
