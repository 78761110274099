
/*
import initialState from './state';

export function SET_VALUE(state, value) {

  state.value = value || null;
}

export function RESET_VALUE(state) {

  state.value = initialState().value;
}
*/
