const {humanDateTime} = require('@/utils/date.js');
const {humanizePrice} = require('@/utils/humanize-price.js');

/**
 * Parsed ticket qr.
 */
class ParsedTicketQr {
  /**
   * Constructor.
   *
   * @param input
   */
  constructor(input) {
    if (!input) return;
    const params = new URLSearchParams(input);
    const result = {}
    for(const [key, value] of params.entries()) {
      result[key] = value;
    }
    this._t = result.t;
    this._s = result.s;
    this._fn = result.fn;
    this._i = result.i;
    this._fp = result.fp;
    this._n = result.n;
  }

  /**
   * Date time.
   *
   * @returns {*|undefined}
   */
  get dateTime() {
    if (this._dateTime != null) return this._dateTime;
    let value = this._t;
    const valid = /(\d{8}T\d{4}(\d{2})?)/.test(value);
    value = valid ? humanDateTime(value) : undefined;
    this._dateTime = value;
    return value;
  }

  /**
   * Total sum.
   *
   * @returns {*|undefined}
   */
  get totalSum() {
    if (this._totalSum != null) return this._totalSum;
    let value = this._s;
    const valid = /^\d+(\.\d{2})?$/.test(value);
    value = valid ? humanizePrice(value) : undefined;
    this._totalSum = value;
    return value;
  }

  /**
   * Fiscal drive number.
   *
   * @returns {*|undefined}
   */
  get fiscalDriveNumber() {
    if (this._fiscalDriveNumber != null) return this._fiscalDriveNumber;
    let value = this._fn;
    if (value == null) return;
    value = value.replace(/^0+/, '');
    const valid = /^\d+$/.test(value) && !/^0+$/.test(value);
    value = valid ? value : undefined;
    this._fiscalDriveNumber = value;
    return value;
  }

  /**
   * Fiscal document number.
   *
   * @returns {*|undefined}
   */
  get fiscalDocumentNumber() {
    if (this._fiscalDocumentNumber != null) return this._fiscalDocumentNumber;
    let value = this._i;
    if (value == null) return;
    value = value.replace(/^0+/, '');
    const valid = /^\d+$/.test(value) && !/^0+$/.test(value);
    value = valid ? value : undefined;
    this._fiscalDocumentNumber = value;
    return value;
  }

  /**
   * Fiscal sign.
   *
   * @returns {*|undefined}
   */
  get fiscalSign() {
    if (this._fiscalSign != null) return this._fiscalSign;
    let value = this._fp;
    if (value == null) return;
    value = value.replace(/^0+/, '');
    const valid = /^\d+$/.test(value) && !/^0+$/.test(value);
    value = valid ? value : undefined;
    this._fiscalSign = value;
    return value;
  }

  /**
   * Operation type.
   *
   * @returns {string}
   */
  get operationType() {
    if (this._operationType != null) return this._operationType;
    let value = this._n;
    if (value == null) return;
    value = value.replace(/^0+/, '');
    if (value === '1') return 'приход';
    if (value === '2') return 'возврат';
    this._operationType = value;
    return value;
  }
}

module.exports.parseTicketQr = function (input) {
  return new ParsedTicketQr(input);
}
