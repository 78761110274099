
export default ({Vue, store}) => {

  const fn = function(callback, options = {}) {
    const message = options.message || 'Подтвердите действие';
    return this.$bvModal.msgBoxConfirm(message, {
      centered: true,
      okTitle: 'Продолжить',
      okVariant: 'primary',
      cancelTitle: 'Отмена',
      cancelVariant: 'secondary',
      contentClass: 'font-size-5',
      footerClass: 'd-flex justify-content-between',
      ...options,
    }).then(value => {

      if (value && callback)
        callback();
    });
  };

  Vue.prototype.$confirm
    = store.$confirm
    = fn;

  const confirmRemove = function (cb, options = {}) {
    this.$confirm(cb, {
      message: 'Подтвердите удаление',
      okVariant: 'danger',
      okTitle: 'Удалить',
      ...options,
    });
  }

  Vue.prototype.$confirmRemove
    = store.$confirmRemove
    = confirmRemove;
}
