import cloneDeep from 'lodash/cloneDeep';
import {orderFromState} from '@/utils/index.js';
import {messageFromError} from '@/utils/index.js';
import {searchStateToFilter} from '@/utils/index.js';

/**
 * Add user.
 *
 * @param state
 * @param commit
 * @param payload
 * @param requestPath
 * @param userModule
 * @returns {Promise<boolean>}
 */
async function addUser(state, commit, payload, requestPath, userModule) {
  commit('SET_LOADING', true);

  const body = {
    userId: payload.userId,
    placeId: payload.placeId,
  }
  const filter = {
    include: state.include,
    fields: state.fields,
  }
  let response;

  try {
    response = await this.$api.post(
      `${requestPath}?filter=${JSON.stringify(filter)}`,
      body,
    );
  } catch (error) {
    this.$notify(
      'error',
      'Ошибка',
      messageFromError(error),
      {duration: 3000, permanent: false},
    );
    return false;
  } finally {
    commit('RESET_LOADING');
  }

  // add place
  if (typeof response.data === 'object') {
    commit('SET_ITEMS', [response.data, ...state.items]);
    commit('SET_TOTAL', state.total +1);
    commit(`${userModule}/ADD_TO_PLACE_IDS`, payload.placeId, {root: true});

    this.$notify(
      'success',
      `Торговая точка добавлена`,
      undefined,
      {duration: 3000, permanent: false},
    );
    return true;
  }

  // no place
  this.$notify(
    'error',
    'Ошибка',
    'Что-то пошло не так',
    {duration: 3000, permanent: false},
  );
  return false;
}

/**
 * Remove user.
 *
 * @param state
 * @param commit
 * @param payload
 * @param requestPath
 * @param userModule
 * @returns {Promise<boolean>}
 */
async function removeUser(state, commit, payload, requestPath, userModule) {
  commit('SET_LOADING', true);

  const body = {
    userId: payload.userId,
    placeId: payload.placeId,
  }
  const filter = {
    include: state.include,
    fields: state.fields,
  }

  try {
    await this.$api.post(
      `${requestPath}?filter=${JSON.stringify(filter)}`,
      body,
    );
  } catch (error) {
    this.$notify(
      'error',
      'Ошибка',
      messageFromError(error),
      {duration: 3000, permanent: false},
    );
    return false;
  } finally {
    commit('RESET_LOADING');
  }

  // remove place
  const places = state.items.filter(v => v.id !== payload.placeId);
  commit('SET_ITEMS', places);
  commit('SET_TOTAL', state.total -1);
  commit(`${userModule}/REMOVE_FROM_PLACE_IDS`, payload.placeId, {root: true});

  this.$notify(
    'success',
    'Торговая точка исключена',
    undefined,
    {duration: 3000, permanent: false},
  );

  return true;
}

/**
 * Add regional manager.
 *
 * @param state
 * @param commit
 * @param payload
 * @returns {Promise<boolean>}
 */
export function ADD_REGIONAL_MANAGER({state, commit}, payload) {
  return addUser.call(
    this,
    state,
    commit,
    payload,
    '/places/addRegionalManager',
    'vlRegionalManagers',
  );
}

/**
 * Remove regional manager.
 *
 * @param state
 * @param commit
 * @param payload
 * @returns {Promise<boolean>}
 */
export function REMOVE_REGIONAL_MANAGER({state, commit}, payload) {
  return removeUser.call(
    this,
    state,
    commit,
    payload,
    '/places/removeRegionalManager',
    'vlRegionalManagers',
  );
}

/**
 * Add sales man.
 *
 * @param state
 * @param commit
 * @param payload
 * @returns {Promise<boolean>}
 */
export function ADD_SALES_MAN({state, commit}, payload) {
  return addUser.call(
    this,
    state,
    commit,
    payload,
    '/places/addSalesMan',
    'vlSalesMans',
  );
}

/**
 * Remove sales man.
 *
 * @param state
 * @param commit
 * @param payload
 * @returns {Promise<boolean>}
 */
export function REMOVE_SALES_MAN({state, commit}, payload) {
  return removeUser.call(
    this,
    state,
    commit,
    payload,
    '/places/removeSalesMan',
    'vlSalesMans',
  );
}

/**
 * Add vendor.
 *
 * @param state
 * @param commit
 * @param payload
 * @returns {Promise<boolean>}
 */
export function ADD_VENDOR({state, commit}, payload) {
  return addUser.call(
    this,
    state,
    commit,
    payload,
    '/places/addVendor',
    'vlVendors',
  );
}

/**
 * Remove vendor.
 *
 * @param state
 * @param commit
 * @param payload
 * @returns {Promise<boolean>}
 */
export function REMOVE_VENDOR({state, commit}, payload) {
  return removeUser.call(
    this,
    state,
    commit,
    payload,
    '/places/removeVendor',
    'vlVendors',
  );
}

/**
 * FETCH_ITEMS_WITH_USER_ID
 *
 * @param {object} context
 * @param {object} payload
 * @return {Promise<Array|void>}
 */
export async function FETCH_ITEMS_WITH_USER_ID(
  {state, commit},
  {filter = {}, noGlobals = false, append = false} = {},
) {

  commit('SET_LOADING', true);

  let mergedFilter = cloneDeep(filter);

  if (!noGlobals) {

    mergedFilter = cloneDeep({
      skip: state.skip,
      limit: state.limit,
      where: state.where,
      include: state.include,
      fields: state.fields,
      order: orderFromState(state),
      ...mergedFilter,
    });

    searchStateToFilter(state, mergedFilter);
  }

  let userId = mergedFilter.where.userId;
  delete mergedFilter.where.userId;
  let exclude = false;
  if (userId && typeof userId === 'object' && 'neq' in userId) {
    userId = userId.neq;
    exclude = true;
  }

  const queryFilter = JSON.stringify(mergedFilter);
  const queryWhere = JSON.stringify(mergedFilter.where || {});

  let countResponse;
  let itemsResponse;

  try {

    itemsResponse = await this.$api.get(
      `/places/find${exclude ? 'Not' : ''}InUser/${userId}?filter=${queryFilter}`,
    );

    countResponse = await this.$api.get(
      `/places/count${exclude ? 'Not' : ''}InUser/${userId}?where=${queryWhere}`,
    );

  } catch (error) {

    this.$notify(
      'error',
      'Ошибка',
      messageFromError(error),
      {duration: 3000, permanent: false},
    );
  }

  if (itemsResponse && countResponse) {

    const itemsData = append
      ? state.items.concat(itemsResponse.data)
      : itemsResponse.data;

    const countData = countResponse.data.count;

    commit('SET_ITEMS', itemsData);
    commit('SET_TOTAL', countData);
    commit('RESET_LOADING');

    return itemsData;
  }

  commit('RESET_LOADING');
}

/**
 * SEARCH_ITEMS_WITH_USER_ID
 *
 * @param {object} context
 * @param {object} payload
 * @return {Promise<Array|void>}
 */
export async function SEARCH_ITEMS_WITH_USER_ID(
  {commit, dispatch},
  {query = '', searchBy = null} = {},
) {

  commit('SET_SEARCH_QUERY', query);
  commit('RESET_SKIP');

  if (searchBy)
    commit('SET_SEARCH_BY', searchBy);

  return dispatch('FETCH_ITEMS_WITH_USER_ID');
}

/**
 * FETCH_MORE_WITH_USER_ID
 *
 * @param {object} context
 * @return {Promise<Array|void>}
 */
export async function FETCH_MORE_WITH_USER_ID(
  {state, commit, dispatch},
) {
  const skip = state.items.length;
  commit('SET_SKIP', skip);

  return dispatch(
    'FETCH_ITEMS_WITH_USER_ID',
    {append: true},
  );
}

/**
 * FETCH_PAGE_WITH_USER_ID
 *
 * @param state
 * @param commit
 * @param dispatch
 * @param page
 * @returns {Promise<*>}
 * @constructor
 */
export async function FETCH_PAGE_WITH_USER_ID(
  {state, commit, dispatch},
  {page = 1} = {},
) {
  const skip = (page - 1) * state.limit;
  commit('SET_SKIP', skip);
  window.scrollTo(0, 0);
  return dispatch('FETCH_ITEMS_WITH_USER_ID');
}
