import {isoDate} from '@/utils';

export default function () {

  return {
    query: '',
    role: null,
    city: null,
    place: null,
    agency: null,
    campaign: null,
    invited: undefined,
    date: isoDate({startOf: 'day'}),
    ticketStatus: '',
    onlineStatus: null,
    createdAt: null,
  };
}
