var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vue-perfect-scrollbar",
    {
      ref: "thread",
      staticClass: "perfect-scrollbar",
      attrs: { settings: _vm.settings },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }