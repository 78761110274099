import Vue from 'vue';
import moment from 'moment';
import api from '@/clients/api';
import messageModel from '@/models/message-model';

export function RESET({commit}) {

  commit('RESET_LOADING');
  commit('RESET_ITEMS');
  commit('RESET_TOTAL');
  commit('RESET_LIMIT');
  commit('RESET_INPUT');
  commit('RESET_SEND_LOADING');
}

export async function LOAD_ITEMS(
  {commit, state},
  {userId, ticketId = null, add = false},
) {
  commit('SET_LOADING', true);
  const filter = {
    order:  state.order,
    offset: state.offset,
    limit:  state.limit,
  };
  if (state.search) {
    const words = state.search.split(' ');
    const fields = [
      'fiscalDocumentNumber',
    ];
    const wordsAnd = words.map(word => {
      word = word.replace(/\+/, '').trim();
      console.log(word);
      return {or: fields.map(field => ({[field]: {like: word, options: 'i'}}))};
    });
    filter.where = {and: wordsAnd};
  }
  const response = await api.get(
    `/messages/byUserId/${userId}?filter=${JSON.stringify(filter)}`,
  ).catch((error) => {
    console.log(error);
    commit('RESET_LOADING');
    Vue.prototype.$notify(
      'error',
      'Ошибка загрузки',
      error.message,
      {duration: 3000, permanent: false},
    );
  });
  if (response) {
    add
      ? commit('SET_ITEMS', [...state.items, ...response.data.items])
      : commit('SET_ITEMS', response.data.items);
    commit('SET_TOTAL', response.data.total);
    commit('RESET_LOADING');
    return response.data;
  }
}

export async function SEND(
  {commit, state, rootState},
  {recipientId, ticketId = null, file = null},
) {

  const text = !file
    ? state.input.trim()
    : '';

  if (!file && text.length < 3) {

    Vue.prototype.$notify(
      'error',
      'Слишком короткое',
      'Сообщение должно содержать не менее 3х символов.',
      {duration: 3000, permanent: false},
    );

    return;
  }

  commit('SET_SEND_LOADING', true);

  const senderId = rootState
    .users
    .sessionUser
    .id;

  const createdAt = moment
    .utc()
    .toISOString();

  let fileData = null;

  if (file) {

    const formData = new FormData();
    formData.append('file', file);

    const uploadOptions = {
      headers: {'Content-Type': 'multipart/form-data'},
    };

    const fileResponse = await api.post(
      '/fileUpload/single',
      formData,
      uploadOptions,
    );

    fileData = fileResponse.data;
  }

  const message = {
    ...Object.cloneDeep(messageModel),
    file: fileData,
    recipientId,
    senderId,
    ticketId,
    createdAt,
    text,
  };

  const response = await api.post(
    '/messages/send',
    message,
  ).catch((error) => {
    console.log(error);
    commit('RESET_SEND_LOADING');

    Vue.prototype.$notify(
      'error',
      'Ошибка отправки',
      error.message,
      {duration: 3000, permanent: false},
    );
  });

  if (response) {

    commit('RESET_INPUT');

    const messages = [
      ...state.items,
      response.data,
    ];

    commit('SET_ITEMS', messages);
    commit('RESET_SEND_LOADING');

    return response.data;
  }
}
