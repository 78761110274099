<template>

  <div
    class="single-lightbox">

    <div
      :class="className"
      @click="onThumbClick(0)"
      class="single-lightbox__thumb cursor-pointer"
      :style="{backgroundImage: $u.toCssUrl(thumb)}">

      <img
        :src="thumb"
        alt="thumbnail"
        class="single-lightbox__thumb-image"/>

    </div>

    <LightGallery
      :images="[large]"
      :index="photoIndex"
      @close="handleHide()"
      :disable-scroll="true"/>

  </div>

</template>

<script>

  import {LightGallery} from "vue-light-gallery";

  export default {
    name: 'single-lightbox',
    props: [
      "thumb",
      "large",
      "className",
    ],
    components: {
      LightGallery
    },
    data() {
      return {
        photoIndex: null
      };
    },
    methods: {
      onThumbClick(index) {
        this.photoIndex = index;
      },
      handleHide() {
        this.photoIndex = null;
      }
    }
  };

</script>

<style lang="scss">

  .single-lightbox {
    display: flex;
    overflow: hidden;

    &__thumb {
      width: 100%;
      height: 100%;
      overflow: hidden;
      background-size: cover;
      background-position: center center;
    }

    &__thumb-image {
      opacity: 0 !important;
    }
  }

</style>
