var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.pages > 1
    ? _c(
        "b-pagination-nav",
        {
          staticClass: "page-pagination",
          attrs: {
            value: _vm.page,
            align: "center",
            "number-of-pages": _vm.pages,
            "link-gen": (v) => `#page-${v}`,
          },
          on: {
            input: function ($event) {
              return _vm.$emit("input", $event)
            },
          },
        },
        [
          _c("template", { slot: "next-text" }, [
            _c("i", { staticClass: "simple-icon-arrow-right" }),
          ]),
          _c("template", { slot: "prev-text" }, [
            _c("i", { staticClass: "simple-icon-arrow-left" }),
          ]),
          _c("template", { slot: "first-text" }, [
            _c("i", { staticClass: "simple-icon-control-start" }),
          ]),
          _c("template", { slot: "last-text" }, [
            _c("i", { staticClass: "simple-icon-control-end" }),
          ]),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }