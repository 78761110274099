import Vue from 'vue';
import router from '@/router';
import api from '@/clients/api';

export async function LOAD_ITEMS({commit, state}) {
  commit('SET_LOADING', true);
  const filter = {
    order:  state.order,
    offset: state.offset,
    limit:  state.limit,
  };
  if (state.search) {
    const words = state.search.split(' ');
    const fields = [
      'name',
      'surname',
      'middleName',
      'email',
      'phone',
    ];
    const wordsAnd = words.map(word => {
      word = word.replace(/\+/, '').trim();
      console.log(word);
      return {or: fields.map(field => ({[field]: {like: word, options: 'i'}}))};
    });
    filter.where = {and: wordsAnd};
  }
  const response = await api.get(
    `/users/vendors?filter=${JSON.stringify(filter)}`,
  ).catch((error) => {
    console.log(error);
    commit('RESET_LOADING');
    Vue.prototype.$notify(
      'error',
      'Ошибка загрузки',
      error.message,
      {duration: 3000, permanent: false},
    );
  });
  if (response) {
    commit('SET_ITEMS', response.data.items);
    commit('SET_TOTAL', response.data.total);
    commit('RESET_LOADING');
    return response.data;
  }
}

export function CHANGE_PAGE({state, commit, dispatch}, payload) {

  commit(
    'SET_OFFSET',
    state.limit * (payload - 1),
  );

  return dispatch('LOAD_ITEMS');
}

export function CHANGE_PAGE_SIZE({commit, dispatch}, payload) {

  commit('SET_LIMIT', payload);
  commit('RESET_OFFSET');
  return dispatch('LOAD_ITEMS');
}

export function CHANGE_ORDER({commit, dispatch}, payload) {

  commit('SET_ORDER', payload);
  return dispatch('LOAD_ITEMS');
}

export function SEARCH_ITEMS({commit, dispatch}, payload) {

  commit('SET_SEARCH', payload);
  commit('SET_OFFSET', 0);
  return dispatch('LOAD_ITEMS');
}

export async function LOAD_ITEM({commit, state}, id) {
  commit('SET_ITEM_LOADING', true);
  const filter = {where: {id}};
  const response = await api.get(
    `/users/vendors?filter=${JSON.stringify(filter)}`,
  ).catch((error) => {
    console.log(error);
    commit('RESET_ITEM_LOADING');
//    router.push('/404');
  });

  if (response) {

    const item = response.data
      && response.data.items
      && response.data.items.length
      && response.data.items[0]
      || null;

    commit('SET_ITEM', item);
    commit('RESET_ITEM_LOADING');

    if (!item)
      // router.push('/404');

    return item;
  }
}

export function RESET({commit}) {

  commit('RESET_LOADING');
  commit('RESET_ITEMS');
  commit('RESET_TOTAL');
  commit('RESET_LIMIT');
  commit('RESET_OFFSET');
  commit('RESET_SEARCH');
  commit('RESET_ORDER');
  commit('RESET_NO_PLACE_ID');
  commit('RESET_PLACE_ID');
  commit('RESET_ITEM');
  commit('RESET_ITEM_LOADING');
}
