var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "empty-status" },
    [
      _vm.loading
        ? _c("b-spinner")
        : _c("span", { staticStyle: { opacity: ".7" } }, [
            _vm._v(" " + _vm._s(_vm.message) + " "),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }