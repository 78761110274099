var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-select",
    {
      staticClass: "options-selector",
      attrs: {
        label: "id",
        value: _vm.value,
        reduce: _vm.reduce,
        options: _vm.items,
        multiple: _vm.multiple,
        disabled: _vm.disabled,
        "filter-by": _vm.filterBy,
        placeholder: _vm.placeholder,
      },
      on: { input: _vm.input },
      scopedSlots: _vm._u([
        {
          key: "option",
          fn: function (option) {
            return [_vm._v(" " + _vm._s(_vm.getLabel(option)) + " ")]
          },
        },
        {
          key: "selected-option",
          fn: function (option) {
            return [_vm._v(" " + _vm._s(_vm.getLabel(option)) + " ")]
          },
        },
      ]),
    },
    [
      _c("span", { attrs: { slot: "no-options" }, slot: "no-options" }, [
        _vm._v(" не найдено "),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }