import initialState from './state';

const HISTORY_RECORDS_LIMIT = 168;

export function RESET(state) {
  Object.assign(state, initialState());
}

export function SET_LOADING(state, value) {
  state.loading = value;
}

export function RESET_LOADING(state) {
  state.loading = initialState().loading;
}

export function SET_STATUS(state, value) {
  state.status = value;
}

export function RESET_STATUS(state) {
  state.status = initialState().status;
}

export function SET_HISTORY(state, value) {
  state.history = value.slice(-HISTORY_RECORDS_LIMIT);
}

export function RESET_HISTORY(state) {
  state.history = initialState().history;
}

export function ADD_HISTORY_RECORD(state, value) {
  const history = state.history.slice(-HISTORY_RECORDS_LIMIT);
  history.push(value);
  state.history = history;
}

export function SET_COUNTER(state, value) {
  state.counter = value;
}

export function RESET_COUNTER(state) {
  state.counter = initialState().counter;
}

export function SET_STOP_REQUESTED(state, value) {
  state.stopRequested = value;
}

export function RESET_STOP_REQUESTED(state) {
  state.stopRequested = initialState().stopRequested;
}
