
import '@/assets/css/sass/bootstrap.scss';
import '@/assets/css/vendor/dropzone.min.css';
import {defaultColor} from '@/constants/config';
/*  if you want use single color theme

- ColorSwitcher component remove in 'src/app.vue' file.
- Remove multicolor section in current file
- Uncomment the two lines below

import "@/assets/css/sass/themes/piaf.light.orange.scss";
import "./main";
*/

/* if you want single color remove this section:multicolor */
let color = defaultColor;

if (localStorage.getItem('themeColor')) {
  color = localStorage.getItem('themeColor');
}
const render = () => {
    import(`@/assets/css/sass/themes/piaf.${color}.scss`).then(x => require('./main'));
};

render();
/* if you want single color remove this section:multicolor */
