
export function isLoggedIn(state) {
  return !!state.user;
}

export function roleName(state) {
  return state.user && state.user.roleName;
}

export function sessionId(state) {
  return state.user && state.user.id;
}
