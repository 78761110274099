import Cookies from 'js-cookie';

export default ({router, store}) => {

  // Resolve user.

  router.beforeEach(async (to, from, next) => {

    // Is logged in.

    if (store.getters['session/isLoggedIn'])
      return next();

    // No access token.

    const accessToken = Cookies.get(
      'accessToken',
    );

    if (!accessToken)
      return next();

    // Resolve user.

    const user = await store.dispatch(
      'session/RESOLVE_USER',
    );

    // Invalid access token.

    if (!user) {

      Cookies.remove(
        'accessToken',
        {path: '/'},
      );
    }

    // Continue.

    return next();
  });

  // Checking roles.

  router.beforeEach((to, from, next) => {

    // Fetching required roles.

    let requiredRoles = [];

    to.matched.forEach(record => {

      if (
        record.meta
        && record.meta.requiredRoles
        && Array.isArray(record.meta.requiredRoles)
      ) requiredRoles.push(
        record.meta.requiredRoles
      );
    });

    // No required roles.

    if (!requiredRoles.length)
      return next();

    // Is not logged in.

    if (!store.getters['session/isLoggedIn'])
      return next({
        path: '/user/login',
        replace: true,
      });

    // Fetching user roles.

    let userRoles = [{name: '$authenticated'}];
    if (store.state.session.user)
      userRoles.push({name: store.state.session.user.roleName});

    // Checking access.

    let forbidden;

    requiredRoles.forEach(route => {

      const found = route.find(
        role => userRoles.some(
          value => value.name === role,
        ),
      );

      if (!found)
        forbidden = true;
    });

    // Error page or continue.

    forbidden
      ? next({path: '/error/403', replace: true})
      : next();
  });
};
