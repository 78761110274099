import {messageFromError} from '@/utils/index.js';

export function FILTER_ITEMS({state, commit, dispatch}, name = '') {

  const {where} = state.filters.find(
    v => v.name === name,
  );

  commit('RESET_SKIP');
  commit('SET_FILTER', name);
  commit('SET_WHERE', where);

  return dispatch('FETCH_ITEMS');
}

export function SET_LIMIT({state, commit, dispatch}, limit) {

  commit('RESET_SKIP');
  commit('SET_LIMIT', limit);

  return dispatch('FETCH_ITEMS');
}

export async function PROCESS_TEMP_ITEM({state, commit}) {
  commit('SET_LOADING', true);
  const ticketId = state.tempItem?.id;
  if (!ticketId)
    this.$notify(
      'error',
      'Не определен идентификатор обрабатываемого чека.',
    );
  const filter = {include: state.include};
  let response;
  try {
    response = await this.$api.get(
      `/tickets/process/${ticketId}?filter=${JSON.stringify(filter)}`,
    );
  } catch (error) {
    this.$notify(
      'error',
      'Ошибка обработки.',
      messageFromError(error),
      {duration: 3000, permanent: false},
    );
  }
  if (response && response.data)
    commit('UPDATE_ITEM', response.data);
  commit('RESET_LOADING');
}

export async function EDIT_TEMP_ITEM({state, commit}) {
  commit('SET_LOADING', true);
  const ticketId = state.tempItem?.id;
  if (!ticketId)
    this.$notify(
      'error',
      'Не определен идентификатор корректируемого чека.',
    );
  const filter = {include: state.include};
  let response;
  try {
    response = await this.$api.post(
      `/tickets/edit/${ticketId}?filter=${JSON.stringify(filter)}`,
      state.tempItem,
    );
  } catch (error) {
    this.$notify(
      'error',
      'Ошибка',
      messageFromError(error),
      {duration: 5000, permanent: false},
    );
  }
  if (response && response.data)
    commit('UPDATE_ITEM', response.data);
  commit('RESET_LOADING');
}
