import Vue from 'vue';
import router from '@/router';
import api from '@/clients/api';
import {messageFromError} from '@/utils/api';

export async function LOAD_ITEMS({commit, state}) {
  commit('SET_LOADING', true);
  const filter = {
    order:  state.order,
    offset: state.offset,
    limit:  state.limit,
  };
  if (state.search) {
    const words = state.search.split(' ');
    const fields = [
      'name',
      'surname',
      'middleName',
      'email',
      'phone',
    ];
    const wordsAnd = words.map(word => {
      word = word.replace(/\+/, '').trim();
      console.log(word);
      return {or: fields.map(field => ({[field]: {like: word, options: 'i'}}))};
    });
    filter.where = {and: wordsAnd};
  }
  const response = await api.get(
    `/users/byRole/salesMan?filter=${JSON.stringify(filter)}`,
  ).catch((error) => {
    console.log(error);
    commit('RESET_LOADING');
    Vue.prototype.$notify(
      'error',
      'Ошибка загрузки',
      error.message,
      {duration: 3000, permanent: false},
    );
  });
  if (response) {
    commit('SET_ITEMS', response.data.items);
    commit('SET_TOTAL', response.data.total);
    commit('RESET_LOADING');
    return response.data;
  }
}

export function CHANGE_PAGE({state, commit, dispatch}, payload) {

  commit(
    'SET_OFFSET',
    state.limit * (payload - 1),
  );

  return dispatch('LOAD_ITEMS');
}

export function CHANGE_PAGE_SIZE({commit, dispatch}, payload) {

  commit('SET_LIMIT', payload);
  commit('RESET_OFFSET');
  return dispatch('LOAD_ITEMS');
}

/*
export function CHANGE_ORDER({commit, dispatch}, payload) {

  commit('SET_ORDER', payload);
  return dispatch('LOAD_ITEMS');
}
*/

export function CHANGE_ORDER({commit, dispatch}, payload) {

  commit('SET_ORDER', payload);
  return dispatch('LOAD_ITEMS');
}

export function CHANGE_CITY_ID({commit, dispatch}, cityId) {

  commit('SET_CITY_ID', cityId);
  commit('SET_OFFSET', 0);
  return dispatch('LOAD_ITEMS');
}

export function SEARCH_ITEMS({commit, dispatch}, payload) {

  commit('SET_SEARCH', payload);
  commit('SET_OFFSET', 0);
  return dispatch('LOAD_ITEMS');
}

export async function LOAD_ITEM({commit, state}, id) {
  commit('SET_ITEM_LOADING', true);
  const filter = {where: {id}};
  const response = await api.get(
    `/users/byRole/salesMan?filter=${JSON.stringify(filter)}`,
  ).catch((error) => {
    console.log(error);
    commit('RESET_ITEM_LOADING');
    // router.push('/404');
  });

  if (response) {

    const item = response.data
      && response.data.items
      && response.data.items.length
      && response.data.items[0]
      || null;

    commit('SET_ITEM', Object.cloneDeep(item));
    commit('SET_TEMP', Object.cloneDeep(item));
    commit('RESET_ITEM_LOADING');

    if (!item)
      // router.push('/404');

    return item;
  }
}

export async function PATCH_ITEM({commit, state}) {

  if (!state.temp || !state.temp.id)
    return;

  commit('SET_ITEM_LOADING', true);

  const id = state.temp.id;
  const data = state.temp;
  const patch = {};

  if ('name' in data)          patch.name = data.name;
  if ('surname' in data)       patch.surname = data.surname;
  if ('middleName' in data)    patch.middleName = data.middleName;
  if ('phone' in data)         patch.phone = data.phone.replace('+', '');
  if ('email' in data)         patch.email = data.email;
  if ('phoneVerified' in data) patch.phoneVerified = !!data.phoneVerified;
  if ('blocked' in data)       patch.blocked = !!data.blocked;
  if ('avatar' in data)        patch.avatar = data.avatar;
  if ('cityIds' in data)       patch.cityIds = data.cityIds || [];

  const response = await api.patch(
    `/users/${id}`,
    patch,
  ).catch((error) => {
    console.log(error);
    Vue.prototype.$notify(
      'error',
      'Ошибка обновления',
      messageFromError(error),
      {duration: 3000, permanent: false},
    );
  });

  if (response) {

    commit('SET_ITEM', response.data);
    commit('SET_TEMP', Object.cloneDeep(response.data));
    commit('SET_ITEM_LOADING', false);

    Vue.prototype.$notify(
      'success',
      'Пользователь обновлен',
      '',
      {duration: 3000, permanent: false},
    );

    return response.data;
  }

  commit('SET_ITEM_LOADING', false);
}

export async function INVITE({commit, state}) {

  if (!state.invitePhone)
    return;

  commit('SET_LOADING', true);

  const response = await api.post('/users/invite', {
    phone: state.invitePhone.replace('+', ''),
    roleName: 'salesMan',
  }).catch((error) => {
    console.log(error);
    commit('RESET_LOADING');

    Vue.prototype.$notify(
      'error',
      'Ошибка приглашения',
      messageFromError(error),
      {duration: 3000, permanent: false},
    );
  });

  if (response) {

    commit('SET_ITEMS', [
      response.data,
      ...state.items,
    ]);

    commit('SET_TOTAL', state.total + 1);
    commit('RESET_LOADING');

    Vue.prototype.$notify(
      'success',
      'Представитель приглашен',
      '',
      {duration: 3000, permanent: false},
    );

    return response.data;
  }

  commit('RESET_LOADING');
}

export async function ADD_PLACE({commit, state}, {userId, placeId}) {

  if (!userId || !placeId)
    return;

  commit('SET_LOADING', true);

  const response = await api.get(
    `/users/addPlace/${userId}/${placeId}`,
  ).catch((error) => {
    console.log(error);
    Vue.prototype.$notify(
      'error',
      messageFromError(error, 'Произошла ошибка.'),
      '',
      {duration: 3000, permanent: false},
    );
  });

  if (response) {

    commit('SET_ITEM', response.data);
    commit('SET_TEMP', Object.cloneDeep(response.data));
    commit('RESET_LOADING');

    Vue.prototype.$notify(
      'success',
      'Место назначено.',
      '',
      {duration: 3000, permanent: false},
    );

    return response.data;
  }

  commit('RESET_LOADING');
}

export async function REMOVE_PLACE({commit, state}, {userId, placeId}) {

  if (!userId || !placeId)
    return;

  commit('SET_LOADING', true);

  const response = await api.get(
    `/users/removePlace/${userId}/${placeId}`,
  ).catch((error) => {
    console.log(error);
    Vue.prototype.$notify(
      'error',
      messageFromError(error, 'Произошла ошибка.'),
      '',
      {duration: 3000, permanent: false},
    );
  });

  if (response) {

    commit('SET_ITEM', response.data);
    commit('SET_TEMP', Object.cloneDeep(response.data));
    commit('RESET_LOADING');

    Vue.prototype.$notify(
      'success',
      'Место снято.',
      '',
      {duration: 3000, permanent: false},
    );

    return response.data;
  }

  commit('RESET_LOADING');
}
