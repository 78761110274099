import VueNotify from './Notify.vue';

export default {
  install(Vue, options = {}) {
    const Constr = Vue.extend(VueNotify);
    const Notify = new Constr();
    Notify.options = Object.assign(Notify.options, options);
    const vm = Notify.$mount();
    document.querySelector('body').appendChild(vm.$el);
    const notify = (type = 'success', title, message, options = {}) => {
      Notify.addItem(type, title, message, options);
    };
    Vue.$notify = notify;
    Vue.prototype.$notify = notify;
  },
};
