
export function ADD_TO_PLACE_IDS(state, placeId) {
  if (!placeId) throw new Error('No placeId to add.');
  let added = false;
  if (state.tempItem) {
    state.tempItem.placeIds = state.tempItem.placeIds || [];
    if (!state.tempItem.placeIds.includes(placeId)) {
      state.tempItem.placeIds.push(placeId);
      added = true;
    }
  }
  if (state.item) {
    state.item.placeIds = state.item.placeIds || [];
    if (!state.item.placeIds.includes(placeId)) {
      state.item.placeIds.push(placeId);
      added = true;
    }
  }
  if (!added) throw new Error('Place id already exists.');
}

export function REMOVE_FROM_PLACE_IDS(state, placeId) {
  if (!placeId) throw new Error('No placeId to remove.');
  let removed = false;
  if (state.tempItem) {
    state.tempItem.placeIds = state.tempItem.placeIds || [];
    state.tempItem.placeIds = state.tempItem.placeIds.filter(id => id !== placeId);
    removed = true;
  }
  if (state.item) {
    state.item.placeIds = state.item.placeIds || [];
    state.item.placeIds = state.item.placeIds.filter(id => id !== placeId);
    removed = true;
  }
  if (!removed) throw new Error('No item or temp item to remove placeId.');
}
