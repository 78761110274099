import {omit} from 'lodash';
import {cloneDeep} from 'lodash';
import {api} from '@/boot/api.js';
import {onError} from '@/store/index.js';
import {onSuccess} from '@/store/index.js';
import {ApiError} from 'vuex-loopback/src/errors/api-error.js';
import {orderFromState} from 'vuex-loopback/src/utils/index.js';
import {searchStateToFilter} from 'vuex-loopback/src/utils/index.js';

export async function FETCH_ITEMS_WITH_PERIOD_PRODUCTS(
  {state, commit},
  {filter = {}, noGlobals = false, append = false} = {},
) {
  commit('SET_LOADING', true);

  let mergedFilter = cloneDeep(filter);

  if (!noGlobals) {

    mergedFilter = cloneDeep({
      skip: state.skip,
      limit: state.limit,
      where: state.where,
      include: state.include,
      fields: state.fields,
      order: orderFromState(state),
      ...mergedFilter,
    });

    searchStateToFilter(state, mergedFilter);
  }

  const mergedWhere = mergedFilter.where || {};
  const queryFilter = JSON.stringify(mergedFilter);
  const countWhere = JSON.stringify(omit(mergedWhere, 'periodId'));

  let countResponse;
  let itemsResponse;

  try {

    itemsResponse = await api.get(
      `/campaignProducts/withPeriodProducts?filter=${queryFilter}`,
    );

    countResponse = await api.get(
      `/campaignProducts/count?where=${countWhere}`,
    );

  } catch (error) {

    onError({
      error: ApiError.from(error),
      action: 'FETCH_ITEMS',
    });
  }

  if (itemsResponse && countResponse) {

    const itemsData = append
      ? state.items.concat(itemsResponse.data)
      : itemsResponse.data;

    const countData = countResponse.data.count;

    commit('SET_ITEMS', itemsData);
    commit('SET_TOTAL', countData);
    commit('RESET_LOADING');

    onSuccess({
      action: 'FETCH_ITEMS',
    });

    return itemsData;
  }

  commit('RESET_LOADING');
}

export async function FETCH_PAGE_WITH_PERIOD_PRODUCTS(
  {state, commit, dispatch},
  page = 1,
) {
  const skip = (page - 1) * state.limit;
  commit('SET_SKIP', skip);

  return dispatch('FETCH_ITEMS_WITH_PERIOD_PRODUCTS');
}
