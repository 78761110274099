import initialState from './state';

export function SET_LOADING(state, value) {

  state.loading = !!value;
}

export function RESET_LOADING(state) {

  state.loading = initialState().loading;
}

export function SET_ERROR(state, value) {

  state.error = value;
}

export function RESET_ERROR(state) {

  state.error = initialState().error;
}

export function SET_SESSION_USER(state, value) {

  state.sessionUser = value;
}

export function RESET_SESSION_USER(state) {

  state.sessionUser = initialState().sessionUser;
}
