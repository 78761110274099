
export function totalPages(state) {

  return Math.ceil(state.total / state.limit);
}

export function page(state) {

  return Math.floor(state.offset / state.limit) + 1;
}

export function orderOptions(state) {

  return [
    {value: 'date', label: 'сначала новые'},
    {value: 'noStatus', label: 'сначала без статуса'},
  ];
}

export function orderOption(state, getters) {

  return getters.orderOptions.find(
    item => item.value === state.order,
  );
}

export function tempTicketItems(state) {

  return state.tempItem.items || [];
}

export function tempTicketScore(state, getters, rootState) {

  let score = 0;

  const products = (rootState.products.items || []);

  getters.tempTicketItems.forEach(item => {

    const product = products.find(
      v => v.id === item.productId,
    );

    if (!product || !product.category)
      return;

    const value = product.category.score * item.quantity;

    score += value;
  });

  return score;
}

export function tempTicketRejected(state) {

  return state.tempItem && state.tempItem.status === 'rejected';
}

export function tempTicketProcessed(state) {

  return state.tempItem && state.tempItem.status === 'processed';
}
