// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/img/placeholder-image.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root {\n  --muted: #909090;\n}\n\n/* Font Size */\n.image-tile {\n  -ms-flex-negative: 0;\n      flex-shrink: 0;\n  overflow: hidden;\n  position: relative;\n}\n.image-tile__image {\n    color: inherit;\n    background-size: cover;\n    background-repeat: no-repeat;\n    background-position: center center;\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n.image-tile__image.contain {\n      background-size: contain;\n      border: 17px solid transparent;\n}\n.image-tile__zoom {\n    background-image: none !important;\n}\n", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
