import initialState from './state';

export function SET_DARK_THEME(state, value) {
  state.darkTheme = !!value;
}

export function RESET_DARK_THEME(state) {
  state.darkTheme = initialState().darkTheme;
}

export function SET_NO_FOOTER(state, value) {
  state.noFooter = Boolean(value);
}

export function RESET_NO_FOOTER(state) {
  state.noFooter = initialState().noFooter;
}

export function HIDE_FOOTER(state) {
  state.noFooter = true;
}
