
export function SELECT_PLACE({commit, state, dispatch}, place) {

  if (
    !state.selectedPlace ||
    state.selectedPlace.id !== place.id
  ) commit('SET_SELECTED_PLACE', place);

  return dispatch('UPDATE_VENDORS');
}

export function SELECT_USER({commit, state, dispatch}, user) {

  if (state.selectedPlace) {

    commit('SET_REMOVAL_USER', user);
    commit('SET_REMOVAL_MODAL', true);

  } else {

    commit('SET_SELECTED_USER', user);
  }
}

export async function TOGGLE_SELECTED_PLACE({commit, state, dispatch}, place) {

  if (state.selectedUser)
    return dispatch(
      'SET_USER_PLACE',
      place,
    );

  if (
    state.selectedPlace &&
    state.selectedPlace.id === place.id
  ) {

    commit('RESET_SELECTED_PLACE');

  } else {

    commit('SET_SELECTED_PLACE', place);
  }

  return dispatch('UPDATE_VENDORS');
}

export async function UPDATE_VENDORS({commit, state, dispatch}) {

  commit('SET_LOADING', true);

  commit(
    'vendors/RESET_SEARCH',
    null,
    {root: true},
  );

  commit(
    'vendors/RESET_OFFSET',
    null,
    {root: true},
  );

  if (state.selectedPlace) {

    commit(
      'vendors/RESET_NO_PLACE_ID',
      null,
      {root: true},
    );

    commit(
      'vendors/RESET_CITY_IDS',
      [],
      {root: true},
    );

    commit(
      'vendors/SET_PLACE_ID',
      state.selectedPlace.id,
      {root: true},
    );

  } else {

    commit(
      'vendors/SET_NO_PLACE_ID',
      true,
      {root: true},
    );

    commit(
      'vendors/SET_CITY_IDS',
      state.cities.map(v => v.id),
      {root: true},
    );

    commit(
      'vendors/RESET_PLACE_ID',
      null,
      {root: true},
    );
  }

  await dispatch(
    'vendors/LOAD_ITEMS',
    null,
    {root: true},
  );

  commit('RESET_LOADING');
}

export function SHOW_INVITE({commit, state, dispatch}, place) {

  if (!place)
    return;

  commit('SET_INVITE_PLACE', place);
  commit('SET_INVITE_MODAL', true);
}

export function HIDE_INVITE({commit}) {

  commit('RESET_INVITE_MODAL');
  commit('RESET_INVITE_PLACE');
  commit('RESET_INVITE_PHONE');
}

export async function SEND_INVITE({commit, state, dispatch}) {

  if (
    !state.invitePlace ||
    !state.invitePhone
  ) return;

  commit('SET_LOADING', true);

  const user = await dispatch('users/INVITE_TO_PLACE', {
    role: 'vendor',
    phone: state.invitePhone,
    placeId: state.invitePlace.id,
  }, {
    root: true,
  });

  if (user) {

    dispatch(
      'SELECT_PLACE',
      state.invitePlace,
    );

  } else {

    commit('RESET_LOADING');
  }

  dispatch('HIDE_INVITE');
}

export async function SET_USER_PLACE({commit, state, dispatch}, place) {

  if (
    !place ||
    !state.selectedUser
  ) return;

  commit('SET_LOADING', true);

  const user = await dispatch('users/SET_PLACE', {
    placeId: place.id,
    userId: state.selectedUser.id,
  }, {
    root: true,
  });

  commit('RESET_SELECTED_USER');

  return dispatch('UPDATE_VENDORS');
}

export function SHOW_REMOVE({commit, state, dispatch}, user) {

  if (!user)
    return;

  commit('SET_REMOVAL_USER', user);
  commit('SET_REMOVAL_MODAL', true);
}

export function HIDE_REMOVE({commit, state}) {

  commit('RESET_REMOVAL_MODAL');
  commit('RESET_REMOVAL_USER');
}

export async function SEND_REMOVE({commit, state, dispatch}) {

  if (!state.removalUser)
    return;

  commit('SET_LOADING', true);

  const user = await dispatch(
    'users/RESET_PLACE',
    state.removalUser.id,
    {root: true},
  );

  if (user) {

    dispatch('UPDATE_VENDORS');

  } else {

    commit('RESET_LOADING');
  }

  dispatch('HIDE_REMOVE');
}

export function RESET({commit}) {

  commit('RESET_USERS');
  commit('RESET_LOADING');
  commit('RESET_SELECTED_PLACE');
  commit('RESET_SELECTED_USER');
  commit('RESET_INVITE_PHONE');
  commit('RESET_INVITE_PLACE');
  commit('RESET_INVITE_MODAL');
  commit('RESET_REMOVAL_USER');
  commit('RESET_REMOVAL_MODAL');
}
