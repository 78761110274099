
export default function () {

  return {
    loading: false,
    status: undefined,
    history: [],
    counter: {delayed: 0},
    stopRequested: false,
  };
}
