import axios from 'axios';
import Cookies from 'js-cookie';

export const api = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 5000,
});

api.interceptors.request.use(config => {

  const accessToken = Cookies.get(
    'accessToken',
  );

  if (accessToken) {
    config.headers = config.headers || {};
    config.headers.Authorization = accessToken;
  }

  // if (
  //   config.data &&
  //   (config.method === 'post' || config.method === 'patch') &&
  //   !(config.data instanceof FormData)
  // ) {
  //   config.data = _.omitBy(config.data, _.isNull);
  // }

  config.url = config.url.replace(/"like"/g, '"ilike"');

  return config;
});

export default ({Vue, store}) => {
  Vue.prototype.$api = api;
  store.$api = api;
}
