import axios from 'axios';
import {store} from '../store';

function createApi() {

  const instance = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
    timeout: 5000,
  });

  instance.interceptors.request.use(config => {

    const accessToken = store
      && store.state
      && store.state.users
      && store.state.users.sessionUser
      && store.state.users.sessionUser.accessToken;

    if (accessToken) {

      config.headers = config.headers || {};
      config.headers.Authorization = accessToken;
    }

    // if (
    //   config.data &&
    //   (config.method === 'post' || config.method === 'patch') &&
    //   !(config.data instanceof FormData)
    // ) {
    //   config.data = _.omitBy(config.data, _.isNull);
    // }

    config.url = config.url.replace(/"like"/g, '"ilike"');
  });

  return instance;
}

export default createApi();
