<template>

  <!-- Row Card -->
  <b-card
    no-body
    class="row-card"
    :class="classes"
    @click="$emit('click')">

    <div
      v-if="selected"
      :class="`accent-tr-${triangleSize}`"/>

    <image-tile
      :width="imageWidth_"
      :height="imageHeight_"
      class="row-card__image"
      v-if="image !== undefined"
      :link="$u.getFileUrl(image, 200)"
      :placeholder-link="imagePlaceholderLink"/>

    <div
      class="pl-2 d-flex flex-grow-1 min-width-zero">

      <div
        :class="[
          'd-flex',
          'card-body',
          'flex-column',
          'flex-lg-row',
          'min-width-zero',
          'align-self-center',
          'align-items-lg-center',
          'justify-content-between',
        ]">

        <div
          :class="titleClass"
          class="column column__title row-card__title">

          <div
            v-html="title"/>

          <div
            v-if="titleBody"
            :class="titleBodyClass"
            class="row-card__title-body">
            {{ titleBody }}
          </div>

          <div
            v-if="titleCaption"
            :class="titleCaptionClass"
            class="row-card__title-caption">
            {{ titleCaption }}
          </div>

        </div>

        <div
          :class="subtitleClass"
          class="column font-size-4"
          v-if="!noSubtitle && subtitle !== undefined">
          {{ subtitle }}
        </div>

        <div
          v-html="body"
          :class="bodyClass"
          v-if="body !== undefined"
          class="column font-size-4"/>

        <div
          :class="captionClass"
          class="column text-small"
          v-if="caption !== undefined">
          {{ caption }}
        </div>

        <div
          v-if="label !== undefined"
          class="column text-small no-line-height">

          <b-badge
            pill
            v-if="label"
            variant="none"
            :class="labelClass || 'bg-secondary text-white'"
            :style="{backgroundColor: labelClass, color: 'white'}">
            {{ label }}
          </b-badge>

        </div>

        <slot
          name="action"
          v-if="$slots.action"/>

      </div>

    </div>

  </b-card>

</template>

<script>

  import ImageTile from "@/components/image/image-tile";

  export default {
    name: 'row-card',
    components: {
      ImageTile,
    },
    props: {
      image: {
        type: Object,
      },
      imageWidth: {
        type: [Number, String],
      },
      imageHeight: {
        type: [Number, String],
      },
      imagePlaceholderLink: {
        type: String,
      },
      title: {
        type: [String, Number],
        default: '',
      },
      titleCaption: {
        type: String,
        default: '',
      },
      subtitle: {
        type: String,
      },
      label: {
        type: String,
      },
      body: {
        type: String,
      },
      caption: {
        type: String,
      },
      color: {
        type: String,
        default: '',
      },
      bordered: {
        type: Boolean,
        default: false,
      },
      titleClass: {
        type: String,
        default: '',
      },
      titleCaptionClass: {
        type: String,
        default: 'text-muted',
      },
      titleBody: {
        type: String,
        default: '',
      },
      titleBodyClass: {
        type: String,
        default: '',
      },
      subtitleClass: {
        type: String,
        default: '',
      },
      bodyClass: {
        type: String,
        default: '',
      },
      captionClass: {
        type: String,
        default: '',
      },
      labelClass: {
        type: [String, Array, Object],
        default: 'bg-secondary text-white',
      },
      noSubtitle: {
        type: Boolean,
        default: false,
      },
      dense: {
        type: Boolean,
        default: false,
      },
      selected: {
        type: Boolean,
        default: false,
      },
      triangleSize: {
        type: [String, Number],
        default: 2,
      },
    },
    computed: {
      classes() {

        return {
          'd-flex': true,
          'flex-row': true,
          selected: this.selected,
          bordered: this.bordered,
          [this.color]: this.color,
          'row-card_dense': this.dense,
          'cursor-pointer': this.$listeners.click,
        };
      },
      imageWidth_() {
        if (this.imageWidth) return this.imageWidth;
        if (this.dense) return 100;
        return 150;
      },
      imageHeight_() {
        if (this.imageHeight) return this.imageHeight;
        if (this.dense) return 65;
        return 110;
      },
    },
  };

</script>

<style lang="scss">

  .row-card {
    color: inherit;

    &_dense.card {

      .card-body {
        padding: 1rem 1.1rem;
      }
    }

    &__title-body {
      font-size: 14px;
      margin-top: .5rem;
      white-space: normal;
    }

    &__title-caption {
      font-size: 14px;
      margin-top: .5rem;
      white-space: normal;
    }

    &__image > * {
      min-height: 100%;
    }

    @media (max-width: 540px) {
      &__image {
        display: none;
      }
    }

    .column,
    .column > .badge {
      min-width: 0;
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .column > .badge {
      max-width: 100%;
      padding-left: 30px;
      padding-right: 30px;
      display: inline-block;
    }

    .column {
      flex: 1;
      flex-shrink: 0;
      text-align: center;

      &__title {
        font-size: 17px;
      }
    }

    .column:last-child {
      text-align: right;
      padding-right: .3rem;
    }

    .column:first-child {
      flex: 2;
      text-align: left;
    }

    @media (max-width: 992px) {

      .column:last-child {
        text-align: left;
        padding-right: 0;
      }

      .column:not(:last-child) {
        text-align: left;
        margin-bottom: 15px;
      }

      .column > .badge {
        display: inline-block;
      }
    }

    @media (min-width: 992px) {
      .column:not(:last-child) {
        margin-bottom: 0;
      }
    }

    .column:not(:last-child) {
      padding-right: 30px;
    }

    &.bordered {
      border: 1px solid rgba(0, 0, 0, .125);
      box-shadow: none;
    }
  }

</style>
