import {io} from 'socket.io-client';

/**
 * Socket service.
 */
export class SocketService {
  /**
   * Socket.IO
   */
  io;

  /**
   * Rooms.
   *
   * @type {Map<any, any>}
   */
  _rooms = new Map();

  /**
   * Middlewares.
   *
   * @type {[]}
   */
  _middlewares = [];

  /**
   * On reconnect.
   *
   * @private
   */
  _onReconnect() {
    const rooms = Array.from(this._rooms.keys());
    rooms.forEach(room => this.join(room));
  }

  /**
   * Connect.
   *
   * @param token
   * @returns {Promise<void>}
   */
  async connect(token) {
    if (this.io && this.io.connected) await this.io.disconnect();
    const url = new URL(process.env['VUE_APP_WS_BASE_URL']);
    const baseUrl = `${url.protocol}//${url.host}`;
    const basePath = url.pathname === '/'
      ? `/socket.io`
      : `${url.pathname}/socket.io`;
    this.io = await io(baseUrl, {
      query: {token},
      path: basePath,
      forceNew: true,
    });
    this.io.io.on('reconnect', this._onReconnect.bind(this));
    this._middlewares.forEach(fn => fn(this.io));
  }

  /**
   * Disconnect.
   *
   * @returns {Promise<void>}
   */
  async disconnect() {
    if (!this.io || !this.io.connected) return;
    return this.io.disconnect();
  }

  /**
   * Join.
   *
   * @param room
   * @param args
   */
  join(room, args) {
    if (!room || typeof room !== 'string')
      throw new Error('The room name must be a non-empty String.');
    if (args) room = this.createRoomName(room, args);
    this.io.emit('join', room);
    this._rooms.set(room, true);
  }

  /**
   * Leave.
   *
   * @param room
   */
  leave(room) {
    if (!room || typeof room !== 'string')
      throw new Error('The room name must be a non-empty String.');
    this.io.emit('leave', room);
    this._rooms.delete(room);
  }

  /**
   * Create room name.
   *
   * @param subject
   * @param args
   * @returns {string}
   */
  createRoomName(subject, args) {
    if (!subject || typeof subject !== 'string')
      throw new Error('The room subject must be a non-empty String.');
    let res = subject;
    if (Array.isArray(args) && args.length) {
      res += '-';
      res += args.join('-');
    } else if (args != null) {
      res += '-' + String(args);
    }
    return res;
  }

  /**
   * Use.
   *
   * @param fn
   */
  use(fn) {
    if (fn instanceof Function) {
      this._middlewares.push(fn);
    } else {
      throw new Error('The socket middleware must be a function.');
    }
  }
}

export default ({Vue, store}) => {
  const socket = new SocketService();
  Vue.prototype.$socket = socket;
  store.$socket = socket;

  // binds incoming events to specific mutations
  socket.use(function (io) {
    io.on(
      'messenger.convHasUpdated',
      data => store.commit('messenger/PUT_CONV', data),
    );
  });
}
