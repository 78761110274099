
export default {
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    label: {
      type: [String, Array, Function],
      default: 'title',
    },
    reduce: {
      type: Function,
    },
    placeholder: {
      type: String,
      default: 'выберите значение',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    filterBy(option, _, search) {

      const label = this.getLabel(option);

      return label.toLowerCase().indexOf(
        search.toLowerCase(),
      ) > -1;
    },
    getLabel(option) {

      let label = '';

      switch (typeof this.label) {

        case 'function':
          label = this.label(option);
          break;

        case 'object':
          label = this.label.reduce(
            (t, i) => t + option[i] + ' ',
            ''
          ).trim();
          break;

        default:
          label = option[this.label];
      }

      return label || 'Без названия';
    },
  },
}
