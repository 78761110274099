
export default ({Vue, store}) => {

  Vue.prototype.$getParent = function (componentName) {
    let component = null
    let parent = this.$parent
    while (parent && !component) {
      if (parent.$options.name === componentName) {
        component = parent
      }
      parent = parent.$parent
    }
    return component
  };
}
