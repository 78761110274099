import Vue from 'vue';
import App from './app';

// BootstrapVue add
import BootstrapVue from 'bootstrap-vue';
// Router & Store add
import router from './router';
import {store} from './store';
// Multi Language Add
import en from './locales/en.json';
import es from './locales/es.json';
import VueI18n from 'vue-i18n';
import {defaultLocale, localeOptions, firebaseConfig} from '@/constants/config';
// Notification Component Add
import Notifications from '@/components/Common/Notification';
// RefreshButton Component Add
import RefreshButton from '@/components/Common/RefreshButton';
// Colxx Component Add
import Colxx from '@/components/Common/Colxx';
// Perfect Scrollbar Add
import vuePerfectScrollbar from 'vue-perfect-scrollbar';
import contentmenu from 'v-contextmenu';
import lineClamp from 'vue-line-clamp';
import VCalendar from 'v-calendar';
import VueScrollTo from 'vue-scrollto';
import GetFileUrlMixin from './mixins/get-file-url-mixin';
// Plugins.
import BigPicture from './plugins/big-picture';
import Moment from './plugins/moment';
import 'viewerjs/dist/viewer.css';
import Viewer from 'v-viewer';
// Boots
import boots from './boot';

// scripts
import './extensions/array';
import './extensions/object';
import './extensions/string';
import './extensions/validator';

Vue.use(Viewer);
Vue.use(BootstrapVue);
Vue.use(VueI18n);
Vue.use(BigPicture);
Vue.use(Moment);

const messages = {en, es};
const locale = (localStorage.getItem('currentLanguage') && localeOptions.filter(x => x.id === localStorage.getItem('currentLanguage')).length > 0) ? localStorage.getItem('currentLanguage') : defaultLocale;
const i18n = new VueI18n({
  locale,
  fallbackLocale: 'en',
  messages,
});

Vue.use(Notifications);
Vue.component('b-refresh-button', RefreshButton);
Vue.component('b-colxx', Colxx);
Vue.component('vue-perfect-scrollbar', vuePerfectScrollbar);
Vue.use(require('vue-shortkey'));

Vue.use(contentmenu);
Vue.use(lineClamp, { /* plugin options */});
Vue.use(VCalendar, {
  masks: {
    "title": "MMMM YYYY",
    "weekdays": "W",
    "navMonths": "MMM",
    "input": ["YYYY-MM-DD"],
    "inputDateTime": ["L h:mm A", "YYYY-MM-DD h:mm A", "YYYY/MM/DD h:mm A"],
    "inputDateTime24hr": ["L HH:mm", "YYYY-MM-DD HH:mm", "YYYY/MM/DD HH:mm"],
    "inputTime": ["h:mm A"],
    "inputTime24hr": ["HH:mm"],
    "dayPopover": "WWW, MMM D, YYYY",
    "data": ["L", "YYYY-MM-DD", "YYYY/MM/DD"],
    "iso": "YYYY-MM-DDTHH:mm:ss.SSSZ"
  },
});
Vue.use(VueScrollTo);

Vue.mixin(GetFileUrlMixin);

// Boots.

boots.forEach(boot => boot({
  Vue,
  router,
  store,
}));

export default new Vue({
  el: '#app',
  i18n,
  router,
  store,
  render: h => h(App),
});
