<template>

  <div
    class="h-100"
    :class="{'dark-theme': darkTheme}">

    <router-view/>

  </div>

</template>

<script>

  import {mapState} from 'vuex';
  import {getDirection} from '@/utils';

  export default {
    beforeMount() {
      const direction = getDirection();
      if (direction.isRtl) {
        document.body.classList.add('rtl');
        document.dir = 'rtl';
        document.body.classList.remove('ltr');
      } else {
        document.body.classList.add('ltr');
        document.dir = 'ltr';
        document.body.classList.remove('rtl');
      }
    },
    computed: {
      ...mapState('global', {
        darkTheme: 'darkTheme',
      }),
    },
  };

</script>
