/**
 * Is phone.
 *
 * @param {*} input
 * @return {boolean}
 */
export function isInn(input) {
  if (typeof input !== 'string') return false;
  return /^[0-9]{12}$/.test(input);
}
