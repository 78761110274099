<template>

  <!-- Image Tile -->
  <component
    :ar="ratio"
    class="image-tile"
    :width="$u.toCssSize(width)"
    :is="withRatio ? 'v-aspect-ratio' : 'div'">

    <div
      :style="styles"
      :class="classes"
      class="image-tile__image"/>

    <single-lightbox
      v-if="zoomLink"
      :large="zoomLink"
      class-name="image-tile__zoom abs-fill"
      thumb="@/assets/img/placeholder-image.png"/>

  </component>

</template>

<script>

  export default {
    name: 'image-tile',
    props: {
      link: {
        type: String,
        default: '',
      },
      zoomLink: {
        type: String,
        default: '',
      },
      contain: {
        type: Boolean,
        default: false,
      },
      width: {
        type: [String, Number],
      },
      height: {
        type: [String, Number],
      },
      color: {
        type: String,
        default: 'white',
      },
      ratio: {
        type: String,
        default: '4:3',
      },
      placeholderLink: {
        type: String,
        default: '/assets/img/placeholder-image.png',
      },
    },
    computed: {
      withRatio() {

        return !this.height;
      },
      styles() {

        const list = {
          backgroundColor: this.color,
          backgroundImage: this.$u.toCssUrl(this.link || this.placeholderLink),
        };

        if (this.height) {

          list.height = this.$u.toCssSize(this.height);
          list.width = this.$u.toCssSize(this.width);
        }

        return list;
      },
      classes() {

        return {
          contain: this.contain,
          [this.color]: this.color,
          'abs-fill': this.withRatio,
        };
      }
    },
  };

</script>

<style lang="scss">

  .image-tile {
    flex-shrink: 0;
    overflow: hidden;
    position: relative;

    &__image {
      color: inherit;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      background-image: url("@/assets/img/placeholder-image.png");

      &.contain {
        background-size: contain;
        border: 17px solid transparent;
      }
    }

    &__zoom {
      background-image: none !important;
    }
  }

</style>
