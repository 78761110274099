var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-col",
    _vm._b({ class: _vm.classes }, "b-col", _vm.options, false),
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }