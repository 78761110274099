var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page-container", class: _vm.classes }, [
    _c("div", { staticClass: "page-container__header" }, [_vm._t("header")], 2),
    _c("div", { staticClass: "page-container__wrapper" }, [
      _c(
        "div",
        { staticClass: "page-container__content", class: _vm.contentClasses },
        [_vm._t("default")],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }