<template>
  <span>
    <h1
      v-if="heading"
      class="breadcrumb__heading">
      {{ $u.cut(String(heading), 30) }}

      <span
        v-if="subtitle"
        class="breadcrumb__subtitle">
        {{ subtitle }}
      </span>

    </h1>
    <b-nav
      class="pt-0 breadcrumb-container d-none d-sm-block d-lg-inline-block">
      <b-breadcrumb
        :items="items"/>
    </b-nav>
  </span>
</template>

<script>

  export default {
    name: 'breadcrumb',
    props: {
      heading: {
        type: [String, Number],
        default: '',
      },
      subtitle: {
        type: [String, Number],
        default: '',
      },
      items: {
        type: Array,
        default: () => [],
      },
    },
  }

</script>

<style lang="scss">

  .breadcrumb {
    &__subtitle {
      color: #909090;
      font-size: .85rem;
      position: relative;
      margin-left: .5rem;
    }
  }

</style>
