
export default function () {

  return {
    items: [],
    loading: false,
    total: 0,
    limit: 12,
    offset: 0,
    search: '',
    // order: 'tickets', // "tickets" or "messages"
    cityId: '',
    item: null,
    temp: null,
    itemLoading: false,
    invitePhone: '+79',
  };
}
