<template>

  <!-- Form Container -->
  <div
    class="form-container">

    <slot
      :valid="valid"/>

  </div>

</template>

<script>

  export default {
    name: 'form-container',
    props: {
      value: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        valid: this.value,
        fields: new Map(),
      };
    },
    methods: {
      update() {
        const valid = Array.from(this.fields.values()).every(v => v);
        this.valid = valid;
        this.$emit('input', valid);
      },
      changed(key, value) {
        this.fields.set(key, value);
        this.update();
      },
      remove(key) {
        this.fields.delete(key);
        this.update();
      },
    },
  };

</script>

<style lang="scss">

  .form-container {
    color: inherit;
  }

</style>
