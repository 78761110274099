import initialState from './state';

export function RESET(state) {
  Object.assign(state, initialState());
}

export function SET_SCOPE(state, value) {
  state.scope = value;
}

export function RESET_SCOPE(state) {
  state.scope = initialState().scope;
}

export function SET_DEVICES(state, value) {
  state.devices = Number(value);
}

export function RESET_DEVICES(state) {
  state.devices = initialState().devices;
}

export function SET_LOADING(state, value) {
  state.loading = Boolean(value);
}

export function RESET_LOADING(state) {
  state.loading = initialState().loading;
}
