
export default function () {

  return {
    loading: false,
    items: [],
    total: 0,
    limit: 12,
    input: '',
    sendLoading: false,
  };
}
