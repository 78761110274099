import Vue from 'vue'
import {messageFromError} from '@/utils/index.js';
import {SET_MESSAGES_ROOM_NAME} from '@/store/messenger/mutations.js';

/**
 * FETCH_MORE
 *
 * @param state
 * @param commit
 * @param dispatch
 * @returns {Promise<*>}
 * @constructor
 */
export async function FETCH_NEXT_MESSAGES({state, commit, dispatch}) {
  if (state.messagesLoading) return;
  if (state.messagesHasMore === false) return;
  commit('SET_MESSAGES_LOADING', true);

  const query = new URLSearchParams({
    limit: state.messagesLimit,
    endDate: state.messagesFirstDate || new Date().toISOString(),
  }).toString();
  const url = `/messages/byRoom/${state.messagesRoomName}?${query}`;
  let response;
  try {
    response = await this.$api.get(url);
  } catch (error) {
    this.$notify(
      'error',
      'Ошибка',
      messageFromError(error),
      {duration: 3000, permanent: false},
    );
  } finally {
    commit('RESET_MESSAGES_LOADING');
  }

  if (response) {
    dispatch(
      'PREPEND_MESSAGES_AND_PRESERVE_SCROLLING',
      response.data.items,
    );
    commit('SET_MESSAGES_HAS_MORE', response.data.rest > 0);
    if (response.data.items.length && response.data.items[0])
      commit('SET_MESSAGES_FIRST_DATE', response.data.items[0].createdAt);
  }

  return state;
}

/**
 * SEND_MESSAGE
 *
 * @param state
 * @param commit
 * @param dispatch
 * @param message
 * @returns {Promise<void>}
 * @constructor
 */
export async function SEND_MESSAGE({state, commit, dispatch}, message) {
  if (state.messagesLoading) return;
  if (!state.messagesRoomName) {
    this.$notify(
      'error',
      'Ошибка',
      'Название комнаты не определено',
      {duration: 3000, permanent: false},
    );
    return;
  }
  if (!message.text && !message.file) {
    this.$notify(
      'error',
      'Пустое сообщение',
      'Введите текст или прикрепите файл',
      {duration: 3000, permanent: false},
    );
    return;
  }
  commit('SET_MESSAGES_LOADING', true);
  const url = `/messages/sendToRoom/${state.messagesRoomName}`;
  let response;
  try {
    response = await this.$api.post(url, message);
  } catch (error) {
    this.$notify(
      'error',
      'Ошибка',
      messageFromError(error),
      {duration: 3000, permanent: false},
    );
  } finally {
    commit('RESET_MESSAGES_LOADING');
  }
  if (response) {
    dispatch(
      'APPEND_MESSAGES_AND_PRESERVE_SCROLLING',
      [response.data],
    );
    commit('RESET_INPUT_TEXT');
  }
}

/**
 * PREPEND_MESSAGES_AND_PRESERVE_SCROLLING
 *
 * @param state
 * @param commit
 * @param messages
 * @returns {undefined}
 * @constructor
 */
export function PREPEND_MESSAGES_AND_PRESERVE_SCROLLING({commit}, messages) {
  const el = window.document.getElementsByClassName('messenger-thread__thread')[0];
  if (!el) {
    console.log('Class ".messenger-thread__thread" is not found.');
    return;
  }
  const fromEnd = Math.floor(el.scrollHeight - el.clientHeight - el.scrollTop);
  commit('PREPEND_MESSAGES', messages);
  Vue.nextTick(() => {
    const fromStart = el.scrollHeight - el.clientHeight - fromEnd;
    const position = Math.floor(fromStart > 0 ? fromStart : 0);
    el.scrollTo(0, position)
  });
}

/**
 * APPEND_MESSAGES_AND_PRESERVE_SCROLLING
 *
 * @param commit
 * @param messages
 * @constructor
 */
export function APPEND_MESSAGES_AND_PRESERVE_SCROLLING({commit}, messages) {
  const el = window.document.getElementsByClassName('messenger-thread__thread')[0];
  if (!el) {
    console.log('Class ".messenger-thread__thread" is not found.');
    return;
  }
  const fromEnd = Math.floor(el.scrollHeight - el.clientHeight - el.scrollTop);
  commit('APPEND_MESSAGES', messages);
  if (fromEnd <= 50)
    Vue.nextTick(() => {
      el.scrollTo(0, el.scrollHeight - el.clientHeight);
    });
}

/**
 * JOIN_ROOM
 *
 * @param state
 * @param commit
 * @param dispatch
 * @param roomSubjectOrName
 * @returns {Promise<void>}
 * @constructor
 */
export async function JOIN_ROOM({state, commit, dispatch}, roomSubjectOrName) {
  let roomName;
  // string arg
  if (
    roomSubjectOrName &&
    typeof roomSubjectOrName === 'string'
  ) {
    roomName = roomSubjectOrName;
  }
  // object arg
  else if (
    roomSubjectOrName &&
    typeof roomSubjectOrName === 'object' &&
    !Array.isArray(roomSubjectOrName)
  ) {
    roomName = this.$socket.createRoomName(
      roomSubjectOrName.type,
      roomSubjectOrName.args,
    );
  }
  // no room name
  if (!roomName) {
    this.$notify(
      'error',
      'Ошибка',
      'Требуется указать название комнаты.',
      {duration: 3000, permanent: false},
    );
    return;
  }
  // если уже подключен к комнате,
  // то завершаем подключение
  if (state.messagesRoomName === roomName) {
    return;
  }
  // если подключен к другой комнате,
  // то отключаемся
  else if (state.messagesRoomName) {
    await dispatch('LEAVE_ROOM');
  }
  // reset state
  commit('RESET_INPUT_TEXT');
  commit('RESET_MESSAGES');
  commit('RESET_MESSAGES_ROOM_NAME');
  commit('RESET_MESSAGES_LIMIT');
  commit('RESET_MESSAGES_LOADING');
  commit('RESET_MESSAGES_HAS_MORE');
  commit('RESET_MESSAGES_FIRST_DATE');
  // получаем сообщения текущей беседы
  commit('SET_MESSAGES_ROOM_NAME', roomName);
  await dispatch('FETCH_NEXT_MESSAGES');
  // слушаем события комнаты
  this.$socket.join(roomName);
  const appendMessage = m => dispatch('APPEND_MESSAGES_AND_PRESERVE_SCROLLING', [m]);
  this.$socket.io.on('messenger.newMessage', appendMessage);
  // отправляем событие прочтения беседы
  // для изменения ее статуса на "прочитано"
  await dispatch('MAKE_CONV_NO_NEW_MESSAGE', roomName);
}

/**
 * LEAVE_ROOM
 *
 * @param state
 * @param commit
 * @returns {Promise<void>}
 * @constructor
 */
export function LEAVE_ROOM({state, commit}) {
  // если есть название комнаты,
  // то выходим из нее и удаляем
  // ее название
  if (state.messagesRoomName) {
    this.$socket.io.off('messenger.newMessage');
    this.$socket.leave(state.messagesRoomName);
    commit('RESET_MESSAGES_ROOM_NAME');
  }
  commit('RESET_INPUT_TEXT');
  commit('RESET_MESSAGES');
  commit('RESET_MESSAGES_LIMIT');
  commit('RESET_MESSAGES_LOADING');
  commit('RESET_MESSAGES_HAS_MORE');
  commit('RESET_MESSAGES_FIRST_DATE');
}

/**
 * FETCH_CONVS
 *
 * @param state
 * @param commit
 * @param dispatch
 * @returns {Promise<*>}
 * @constructor
 */
export async function FETCH_CONVS({state, commit, dispatch}) {
  commit('RESET_CONVS');
  commit('RESET_CONVS_LIMIT');
  commit('RESET_CONVS_HAS_MORE');
  commit('RESET_CONVS_LOADING');
  commit('RESET_CONVS_TOTAL');
  await dispatch('FETCH_NEXT_CONVS');
  if (!state.messagesRoomName && state.convs.length)
    dispatch('JOIN_ROOM', state.convs[0].roomName);
}

/**
 * FETCH_NEXT_CONVS
 *
 * @param state
 * @param commit
 * @returns {Promise<*>}
 * @constructor
 */
export async function FETCH_NEXT_CONVS({state, commit}) {
  if (state.convsHasMore === false) return;
  commit('SET_CONVS_LOADING', true);
  const query = new URLSearchParams({
    limit: state.convsLimit,
    skip: state.convs.length,
  }).toString();
  const url = `/messages/myConvs?${query}`;
  let response;
  try {
    response = await this.$api.get(url);
  } catch (error) {
    this.$notify(
      'error',
      'Ошибка',
      messageFromError(error),
      {duration: 3000, permanent: false},
    );
  } finally {
    commit('RESET_CONVS_LOADING');
  }
  if (response) {
    commit('ADD_CONVS', response.data.items);
    const hasMore = state.convs.length < response.data.total;
    commit('SET_CONVS_HAS_MORE', hasMore);
    commit('SET_CONVS_TOTAL', response.data.total);
  }
}

/**
 * MAKE_CONV_NO_NEW_MESSAGE
 *
 * @param state
 * @param commit
 * @param roomName
 * @returns {Promise<void>}
 * @constructor
 */
export async function MAKE_CONV_NO_NEW_MESSAGE({state, commit}, roomName) {
  if (!roomName) {
    this.$notify(
      'error',
      'Ошибка',
      'Название комнаты не определено.',
      {duration: 3000, permanent: false},
    );
    return;
  }
  const conv = state.convs.find(v => v.roomName === roomName);
  if (!conv || !conv.hasNewMessage) return;
  commit('SET_NO_NEW_MESSAGE', roomName);
  this.$socket.io.emit('messenger.hasReadConv', roomName);
}
