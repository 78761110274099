import validator from 'validator';

/**
 * Is a Russian mobile number starts with +79..
 *
 * @param  {string}  string
 * @return {boolean}
 */
validator.isMobilePhoneStrictRU = (string) => {
  const regex = /^\+79\d{9}$/;

  return validator.isMobilePhone(string, 'ru-RU')
    && regex.test(string);
};
