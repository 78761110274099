
export default ({router, store}) => {

  // Reset state.

  router.beforeEach((to, from, next) => {

    if (
      from &&
      from.name &&
      from.name !== to.name
    ) {
      store.commit('vlPlans/RESET');
      store.commit('vlUsers/RESET');
      store.commit('vlCities/RESET');
      store.commit('vlPlaces/RESET');
      store.commit('commands/RESET');
      store.commit('vlVendors/RESET');
      store.commit('vlRegions/RESET');
      store.commit('vlTickets/RESET');
      store.commit('vlPeriods/RESET');
      store.commit('vlProducts/RESET');
      store.commit('vlSalesMans/RESET');
      store.commit('vlCampaigns/RESET');
      store.commit('vlPlaceTypes/RESET');
      store.commit('vlAdmEntities/RESET');
      store.commit('collectionFilter/RESET');
      store.commit('vlSelectorPlaces/RESET');
      store.commit('vlPeriodProducts/RESET');
      store.commit('vlSelectorVendors/RESET');
      store.commit('vlCampaignProducts/RESET');
      store.commit('vlRegionalManagers/RESET');
      store.commit('vlDupUsersForRoute/RESET');
      store.commit('ticketAutoProcessor/RESET');
      store.commit('vlSelectorSalesMans/RESET');
      store.commit('vlDupPlacesForRoute/RESET');
      store.commit('vlSelectorRegionalManagers/RESET');
    }

    next();
  });
};
