
export function sortedItems(state) {

  const items = Array.clone(state.items);

  items.sort(
    (a, b) => a.createdAt < b.createdAt
      ? -1
      : (a.createdAt > b.createdAt) ? 1 : 0,
  );

  return items.reverse();
}

export function canLoadMore(state) {

  return state.total > state.items.length;
}
