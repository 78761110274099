import createState from './state';
import * as getters from './getters';
import * as mutations from './mutations';
import * as actions from './actions';

export default function () {
  return {
    namespaced: true,
    state: createState(),
    getters,
    mutations,
    actions,
  };
}
