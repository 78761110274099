import initialState from './state';

export function SET_LOADING(state, value) {

  state.loading = !!value;
}

export function RESET_LOADING(state) {

  state.loading = initialState().loading;
}

export function SET_ITEMS(state, value) {

  state.items = value;
}

export function RESET_ITEMS(state) {

  state.items = initialState().items;
}

export function SET_TOTAL(state, value) {

  state.total = value;
}

export function RESET_TOTAL(state) {

  state.total = initialState().total;
}

export function SET_LIMIT(state, value) {

  state.limit = value;
}

export function RESET_LIMIT(state) {

  state.limit = initialState().limit;
}

export function SET_INPUT(state, value) {

  state.input = value;
}

export function RESET_INPUT(state) {

  state.input = initialState().input;
}

export function SET_SEND_LOADING(state, value) {

  state.sendLoading = !!value;
}

export function RESET_SEND_LOADING(state) {

  state.sendLoading = initialState().sendLoading;
}
