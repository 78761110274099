<template>

  <!-- Perfect Scrollbar -->
  <vue-perfect-scrollbar
    ref="thread"
    :settings="settings"
    class="perfect-scrollbar">

    <slot/>

  </vue-perfect-scrollbar>

</template>

<script>

  import debounce from 'lodash/debounce.js';

  export default {
    name: 'perfect-scrollbar',
    props: {
      onTopDebounce: {
        type: Number,
        default: 500,
      },
    },
    data() {
      return {
        settings: {
          suppressScrollX: true,
          wheelPropagation: false,
        },
      };
    },
    computed: {
      onScroll() {
        return debounce(event => {
          const el = event.target;
          if (el.scrollTop < 1) {
            // console.log(`onTop ${el.className}`);
            this.$emit('onTop');
          }
          const fromEnd = el.scrollHeight - el.clientHeight - el.scrollTop;
          if (fromEnd < 1) {
            // console.log(`onBottom ${el.className}`);
            this.$emit('onBottom');
          }
        }, this.onTopDebounce);
      },
    },
    mounted() {
      if (this.$el) this.$el.addEventListener('scroll', this.onScroll);
    },
    beforeDestroy() {
      if (this.$el) this.$el.removeEventListener('scroll', this.onScroll);
    },
  };

</script>

<style lang="scss">

  .perfect-scrollbar {
    color: inherit;
    margin-right: 0;
    padding-right: 0;

    .ps__rail-y {
      width: 6px !important;
    }

    .ps__thumb-y {
      right: 0 !important;
    }

    .ps__rail-y.ps--clicking .ps__thumb-y,
    .ps__rail-y:focus>.ps__thumb-y,
    .ps__rail-y:hover>.ps__thumb-y {
      width: 6px !important;
    }
  }

</style>
