var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: {
        "notification-container": true,
        "notification-container-empty": _vm.items.length === 0,
      },
    },
    [
      _c(
        "transition-group",
        { attrs: { name: "ntf", tag: "div", mode: "out" } },
        _vm._l(_vm.items, function (item) {
          return _c(
            "div",
            {
              key: item.id,
              class: "notification notification-" + item.options.type,
              on: {
                click: function ($event) {
                  return _vm.removeItem(item.id)
                },
              },
            },
            [
              _c("div", { staticClass: "notification-message" }, [
                item.title
                  ? _c("h4", { staticClass: "title" }, [
                      _vm._v(_vm._s(item.title)),
                    ])
                  : _vm._e(),
                item.message
                  ? _c("div", {
                      staticClass: "message",
                      domProps: { innerHTML: _vm._s(item.message) },
                    })
                  : _vm._e(),
                item.options.permanent
                  ? _c("div", { staticClass: "mt-3 message" }, [
                      _c("i", {
                        staticClass:
                          "iconsminds-cursor-click-2 mr-1 font-size-2",
                      }),
                      _c("small", [_vm._v("нажмите, что бы закрыть")]),
                    ])
                  : _vm._e(),
              ]),
            ]
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }