<template>

  <!-- Form Input Validator -->
  <div
    class="form-input-validator"
    :class="valid ? 'valid' : 'invalid'">

    <slot/>

  </div>

</template>

<script>

  export default {
    name: 'form-input-validator',
    props: {
      value: {},
      cond: {
        type: Function,
      },
    },
    computed: {
      valid() {
        if (!this.cond) return true;
        const value = Boolean(this.cond(this.value));
        if (this.form) this.form.changed(this, value);
        return value;
      },
    },
    mounted() {
      this.form = this.$getParent('form-container');
      if (this.form) this.form.changed(this, this.valid);
    },
    beforeDestroy() {
      if (this.form) this.form.remove(this);
    },
  };

</script>

<style lang="scss">

  .form-input-validator {
    color: inherit;

    .input-group-prepend .input-group-text {
      position: relative;
    }

    .input-group-prepend .input-group-text:after {
      top: -1px;
      left: -1px;
      width: 0;
      height: 0;
      content: '';
      display: block;
      position: absolute;
      border-style: solid;
      border-width: 10px 10px 0 0;
    }

    &.valid .input-group-prepend .input-group-text:after {
      border-color: transparent;
    }

    &.invalid .input-group-prepend .input-group-text:after {
      border-color: red transparent transparent transparent;
    }
  }

</style>
