import {cloneDeep} from 'lodash';
import {orderFromState} from '@/utils/index.js';
import {messageFromError} from '@/utils/index.js';
import {searchStateToFilter} from '@/utils/index.js';

/**
 * FETCH_ITEMS_WITH_CAMPAIGN_ID
 *
 * @param {object} context
 * @param {object} payload
 * @return {Promise<Array|void>}
 */
export async function FETCH_ITEMS_WITH_CAMPAIGN_ID(
  {state, commit},
  {filter = {}, noGlobals = false, append = false} = {},
) {

  commit('SET_LOADING', true);

  let mergedFilter = cloneDeep(filter);

  if (!noGlobals) {

    mergedFilter = cloneDeep({
      skip: state.skip,
      limit: state.limit,
      where: state.where,
      include: state.include,
      fields: state.fields,
      order: orderFromState(state),
      ...mergedFilter,
    });

    searchStateToFilter(state, mergedFilter);
  }

  let campaignId = mergedFilter.where.campaignId;
  delete mergedFilter.where.campaignId;
  let exclude = false;
  if (campaignId && typeof campaignId === 'object' && 'neq' in campaignId) {
    campaignId = campaignId.neq;
    exclude = true;
  }

  const queryFilter = JSON.stringify(mergedFilter);
  const queryWhere = JSON.stringify(mergedFilter.where || {});

  let countResponse;
  let itemsResponse;

  try {

    itemsResponse = await this.$api.get(
      `/products/find${exclude ? 'Not' : ''}InCampaign/${campaignId}?filter=${queryFilter}`,
    );

    countResponse = await this.$api.get(
      `/products/count${exclude ? 'Not' : ''}InCampaign/${campaignId}?where=${queryWhere}`,
    );

  } catch (error) {

    this.$notify(
      'error',
      'Ошибка',
      messageFromError(error),
      {duration: 3000, permanent: false},
    );
  }

  if (itemsResponse && countResponse) {

    const itemsData = append
      ? state.items.concat(itemsResponse.data)
      : itemsResponse.data;

    const countData = countResponse.data.count;

    commit('SET_ITEMS', itemsData);
    commit('SET_TOTAL', countData);
    commit('RESET_LOADING');

    return itemsData;
  }

  commit('RESET_LOADING');
}

/**
 * SEARCH_ITEMS_WITH_CAMPAIGN_ID
 *
 * @param {object} context
 * @param {object} payload
 * @return {Promise<Array|void>}
 */
export async function SEARCH_ITEMS_WITH_CAMPAIGN_ID(
  {commit, dispatch},
  {query = '', searchBy = null} = {},
) {

  commit('SET_SEARCH_QUERY', query);
  commit('RESET_SKIP');

  if (searchBy)
    commit('SET_SEARCH_BY', searchBy);

  return dispatch('FETCH_ITEMS_WITH_CAMPAIGN_ID');
}

/**
 * FETCH_MORE_WITH_CAMPAIGN_ID
 *
 * @param {object} context
 * @return {Promise<Array|void>}
 */
export async function FETCH_MORE_WITH_CAMPAIGN_ID(
  {state, commit, dispatch},
) {
  const skip = state.items.length;
  commit('SET_SKIP', skip);

  return dispatch(
    'FETCH_ITEMS_WITH_CAMPAIGN_ID',
    {append: true},
  );
}

/**
 * FETCH_PAGE_WITH_CAMPAIGN_ID
 *
 * @param state
 * @param commit
 * @param dispatch
 * @param page
 * @returns {Promise<*>}
 * @constructor
 */
export async function FETCH_PAGE_WITH_CAMPAIGN_ID(
  {state, commit, dispatch},
  {page = 1} = {},
) {
  const skip = (page - 1) * state.limit;
  commit('SET_SKIP', skip);
  window.scrollTo(0, 0);
  return dispatch('FETCH_ITEMS_WITH_CAMPAIGN_ID');
}
