
export function selectedPlaceTitle(state) {

  return state.selectedPlace
    ? state.selectedPlace.title
    : '';
}

export function usersTitle(state) {

  return state.selectedPlace
    ? state.selectedPlace.title
    : 'Все продавцы';
}

export function inviteTitle(state) {

  return state.invitePlace
    ? `Приглашение ${state.invitePlace.title}`
    : '';
}

export function removalTitle(state, getters) {

  return state.removalUser
    ? `Продавец ${getters.removalUserName}`
    : '';
}

export function removalUserName(state) {

  if (!state.removalUser)
    return '';

  return state.removalUser.name || state.removalUser.surname
    ? `${state.removalUser.name} ${state.removalUser.surname}`
    : `+${state.removalUser.phone}`;
}
