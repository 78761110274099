var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.withRatio ? "v-aspect-ratio" : "div",
    {
      tag: "component",
      staticClass: "image-tile",
      attrs: { ar: _vm.ratio, width: _vm.$u.toCssSize(_vm.width) },
    },
    [
      _c("div", {
        staticClass: "image-tile__image",
        class: _vm.classes,
        style: _vm.styles,
      }),
      _vm.zoomLink
        ? _c("single-lightbox", {
            attrs: {
              large: _vm.zoomLink,
              "class-name": "image-tile__zoom abs-fill",
              thumb: "@/assets/img/placeholder-image.png",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }