import api from '@/clients/api';

export default {
  state: {
    currentUser: localStorage.getItem('user') != null ? JSON.parse(localStorage.getItem('user')) : null,
    loginError: null,
    processing: false,
  },
  getters: {
    currentUser: state => state.currentUser,
    processing: state => state.processing,
    loginError: state => state.loginError,
  },
  mutations: {
    setUser(state, payload) {
      state.currentUser = payload;
      state.processing = false;
      state.loginError = null;
    },
    setLogout(state) {
      state.currentUser = null;
      state.processing = false;
      state.loginError = null;
    },
    setProcessing(state, payload) {
      state.processing = payload;
      state.loginError = null;
    },
    setError(state, payload) {
      state.loginError = payload;
      state.currentUser = null;
      state.processing = false;
    },
    clearError(state) {
      state.loginError = null;
    },
  },
  actions: {
    login({commit}, payload) {
      commit('clearError');
      commit('setProcessing', true);

      api.post(
        '/users/login',
        {phone: payload.login.replace(/\+/, ''), password: payload.password},
      ).then(({data}) => {
        localStorage.setItem('user', JSON.stringify(data));
        commit('setUser', data);
      }).catch((error) => {
        console.log(error);
        localStorage.removeItem('user');
        commit('setError', error.message);
      });
    },
    signOut({commit}) {
      localStorage.removeItem('user');
      commit('setLogout');
    },
  },
};
