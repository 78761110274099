
export function toCssSize(value) {

  if (typeof value === 'number')
    return `${value}px`;

  return typeof value !== 'string'
    ? null
    : value;
}

/**
 * To Css Url.
 *
 * @param value
 * @return {string|null}
 */
export function toCssUrl(value) {

  if (value && typeof value === 'string')
    return `url("${value}")`;

  return null;
}

/**
 * To Css Color.
 *
 * @param value
 * @return {string}
 */
export function toCssColor(value) {

  return value.match(/^#[a-f0-9]{6}$/i) !== null
    ? value
    : '';
}
