import Vue from 'vue';
import _ from 'lodash';
import router from '@/router';
import api from '@/clients/api';
import {messageFromError} from '@/utils/api';

export async function LOAD_ITEMS(
  {commit, state},
  {reset = false, userId = null} = {},
) {
  commit('SET_LOADING', true);
  const filter = {
    order:  state.order,
    offset: state.offset,
    limit:  state.limit,
  };
  if (state.search) {
    const words = state.search.split(' ');
    const fields = [
      'fiscalDocumentNumber',
    ];
    const wordsAnd = words.map(word => {
      word = word.replace(/\+/, '').trim();
      console.log(word);
      return {or: fields.map(field => ({[field]: {like: word, options: 'i'}}))};
    });
    filter.where = {and: wordsAnd};
  }
  const response = await api.get(
    `/tickets/all?filter=${JSON.stringify(filter)}`,
  ).catch((error) => {
    console.log(error);
    commit('RESET_LOADING');
    Vue.prototype.$notify(
      'error',
      'Ошибка загрузки',
      error.message,
      {duration: 3000, permanent: false},
    );
  });
  if (response) {
    commit('SET_ITEMS', response.data.items);
    commit('SET_TOTAL', response.data.total);
    commit('RESET_LOADING');
    return response.data;
  }
}

export function CHANGE_PAGE({state, commit, dispatch}, payload) {

  commit(
    'SET_OFFSET',
    state.limit * (payload - 1),
  );

  return dispatch('LOAD_ITEMS');
}

export function CHANGE_PAGE_SIZE({commit, dispatch}, payload) {

  commit('SET_LIMIT', payload);
  commit('RESET_OFFSET');
  return dispatch('LOAD_ITEMS');
}

export function CHANGE_ORDER({commit, dispatch}, payload) {

  commit('SET_ORDER', payload);
  return dispatch('LOAD_ITEMS');
}

export function SEARCH_ITEMS({commit, dispatch}, payload) {

  commit('SET_SEARCH', payload);
  commit('SET_OFFSET', 0);
  return dispatch('LOAD_ITEMS');
}

export async function LOAD_ITEM(
  {commit, state, dispatch},
  {userId, ticketId},
) {

  commit('SET_ITEM_LOADING', true);

  const where = {
    userId,
    id: ticketId,
  };

  // const include = [
  //   {relation: 'user'},
  //   {relation: 'place'},
  //   {relation: 'campaign'},
  // ];

  let response;

  try {

    response = await api.get(
      `/tickets?filter=${JSON.stringify({
        where,
      })}`,
    );

  } catch (error) {

    commit('RESET_ITEM_LOADING');
    router.push('/404');

    return;
  }

  const item = (
    response.data
    && response.data.length
    && response.data[0]
  ) || null;

  commit('SET_ITEM', item);
  commit('SET_TEMP_ITEM', item);

  if (item && item.userId)
    await dispatch(
      'PUT_DETAILS_TO_TEMP',
      {userId: item.userId},
    );

  commit('RESET_ITEM_LOADING');

  return item;
}

export async function PUT_TEMP_ITEM({commit, state}) {

  if (
    !state.tempItem ||
    !state.tempItem.id
  ) return;

  const id = state.tempItem.id;
  const data = _.cloneDeep(state.tempItem);

  delete data.id;
  delete data.user;
  delete data.place;
  delete data.campaign;

  commit('SET_ITEM_LOADING', true);

  const response = await api.patch(
    `/tickets/${id}`,
    data,
  ).catch((error) => {
    console.log(error);
    commit('RESET_ITEM_LOADING');
    throw error;
  });

  if (response) {

    const item = {
      ...state.tempItem,
      ...data,
    };

    commit('SET_ITEM', item);
    commit('SET_TEMP_ITEM', item);
    commit('RESET_ITEM_LOADING');

    if (!item)
      // router.push('/404');

    return data;
  }

  commit('RESET_ITEM_LOADING');
}

export async function SET_PRODUCT(
  {commit, state, rootState},
  {productId, quantity, price} = {},
) {

  if (!state.tempItem)
    return;

  const items = state.tempItem.items || [];

  const index = items.findIndex(
    v => v.productId === productId,
  );

  const product = (rootState.products.items || []).find(
    v => v.id === productId,
  );

  if (!product)
    return;

  const category = product.category;

  if (!product || !category)
    return;

  price = price || product.category.recommendedPrice;

  let totalSum = state.tempItem.totalSum || 0;

  // Remove item.

  if (index > -1) {

    const price = items[index].price || 0;
    const quantity = items[index].quantity || 0;
    totalSum -= price * quantity;

    items.splice(index, 1);
  }

  // Add item.

  if (quantity) {

    const sum = price * quantity;
    totalSum += sum;

    items.push({
      sum,
      price,
      quantity,
      productId,
      name: product.shortTitle,
    });
  }

  commit('SET_TEMP_ITEM', {
    ...state.tempItem,
    totalSum,
    items,
  });

  return items;
}

export async function REJECT_ITEM(
  {commit, state},
) {
  if (
    !state.tempItem ||
    !state.tempItem.id
  ) return;

  const id = state.tempItem.id;
  const data = {status: 'rejected'};

  commit('SET_ITEM_LOADING', true);

  const response = await api.patch(
    `/tickets/${id}`,
    data,
  ).catch((error) => {
    console.log(error);
    commit('RESET_ITEM_LOADING');
    throw error;
  });

  if (response) {

    const item = {
      ...state.tempItem,
      ...data,
    };

    commit('SET_ITEM', item);
    commit('SET_TEMP_ITEM', item);
    commit('RESET_ITEM_LOADING');

    if (!item)
      // router.push('/404');

    return data;
  }

  commit('RESET_ITEM_LOADING');
}

export async function PROCESS_ITEM(
  {commit, state, getters},
) {
  if (
    !state.tempItem ||
    !state.tempItem.id
  ) return;

  if (state.tempItem.status === 'processed')
    Vue.prototype.$notify(
      'error',
      'Ошибка начисления',
      'По чеку уже произведено начисление.',
      {duration: 3000, permanent: false},
    );

  const data = {
    ticketId: state.tempItem.id,
    score: getters.tempTicketScore,
  };

  commit('SET_ITEM_LOADING', true);

  const response = await api.post(
    '/tickets/manualProcess',
    data,
  ).catch((error) => {
    console.log(error);
    Vue.prototype.$notify(
      'error',
      'Ошибка начисления',
      messageFromError(error),
      {duration: 3000, permanent: false},
    );
  });

  if (response) {

    const item = {
      ...state.tempItem,
      status: response.data.status,
      score: response.data.score,
    };

    commit('SET_ITEM', item);
    commit('SET_TEMP_ITEM', item);
    commit('RESET_ITEM_LOADING');

    return data;
  }

  commit('RESET_ITEM_LOADING');
}

export async function PUT_DETAILS_TO_TEMP({commit, state}, {userId}) {

  if (!state.tempItem)
    return;

  const response = await api.get(
    `/ticket/userDetails/${userId}`,
  ).catch(console.log);

  if (!response)
    return;

  commit(
    'SET_ITEM_DETAILS',
    response.data,
  );

  const fiscalDriveNumber
    = state.tempItem.fiscalDriveNumber
    || response.data.fiscalDriveNumber;

  /*
  const fiscalSign
    = state.tempItem.fiscalSign
    || response.data.fiscalSign;
  */

  const userInn
    = state.tempItem.userInn
    || response.data.userInn;

  const kktRegId
    = state.tempItem.kktRegId
    || response.data.kktRegId;

  const operator
    = state.tempItem.operator
    || response.data.operator;

  const ofd
    = state.tempItem.ofd
    || response.data.ofd;

  commit('SET_TEMP_ITEM', {
    ...state.tempItem,
    fiscalDriveNumber,
    // fiscalSign,
    userInn,
    kktRegId,
    operator,
    ofd,
  });
}
