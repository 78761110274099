var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "single-lightbox" },
    [
      _c(
        "div",
        {
          staticClass: "single-lightbox__thumb cursor-pointer",
          class: _vm.className,
          style: { backgroundImage: _vm.$u.toCssUrl(_vm.thumb) },
          on: {
            click: function ($event) {
              return _vm.onThumbClick(0)
            },
          },
        },
        [
          _c("img", {
            staticClass: "single-lightbox__thumb-image",
            attrs: { src: _vm.thumb, alt: "thumbnail" },
          }),
        ]
      ),
      _c("LightGallery", {
        attrs: {
          images: [_vm.large],
          index: _vm.photoIndex,
          "disable-scroll": true,
        },
        on: {
          close: function ($event) {
            return _vm.handleHide()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }