export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'en';
export const defaultDirection = 'ltr';
export const localeOptions = [
  {id: 'en', name: 'English LTR', direction: 'ltr'},
  {id: 'es', name: 'Español', direction: 'ltr'},
  {id: 'enrtl', name: 'English RTL', direction: 'rtl'},
];
export const apiUrl = 'https://api.coloredstrategies.com';

export const currentUser = {
  id: 1,
  title: 'Sarah Kortney',
  img: '@/assets/img/profile-pic-l.jpg',
  date: 'Last seen today 15:24',
};

export const defaultColor = 'light.blue';
export const colors = [
  'light.purple',
  'dark.purple',
  'light.blue',
  'dark.blue',
  'light.green',
  'dark.green',
  'light.orange',
  'dark.orange',
  'light.red',
  'dark.red',
];
