import {cloneDeep} from 'lodash';
import {api} from '@/boot/api.js';
import {onError} from '@/store/index.js';
import {onSuccess} from '@/store/index.js';
import {messageFromError} from '@/utils/index.js';
import {addPlaceToUser} from '@/store/actions/index.js';
import {removePlaceFromUser} from '@/store/actions/index.js';
import {ApiError} from 'vuex-loopback/src/errors/api-error.js';
import {orderFromState} from 'vuex-loopback/src/utils/index.js';
import {searchStateToFilter} from 'vuex-loopback/src/utils/index.js';

/**
 * Invite user.
 *
 * @param state
 * @param commit
 * @param rootGetters
 * @param payload
 * @returns {Promise<boolean>}
 * @constructor
 */
export async function INVITE({state, commit, rootGetters}, payload) {

  commit('SET_LOADING', true);

  const body = {
    name: payload.name.trim(),
    surname: payload.surname.trim(),
    phone: payload.phone.trim().replace(/^\+/, ''),
    email: payload.email.trim(),
    inn: payload.inn.trim(),
    cityId: payload.cityId,
    inviterId: payload.inviterId,
  }
  const filter = {
    include: state.include,
    fields: state.fields,
  }
  let response;

  try {
    response = await this.$api.post(
      `/vendors/invite?filter=${JSON.stringify(filter)}`,
      body,
    );
  } catch (error) {
    this.$notify(
      'error',
      'Ошибка',
      messageFromError(error),
      {duration: 3000, permanent: false},
    );
    return false;
  } finally {
    commit('RESET_LOADING');
  }

  // add user
  if (typeof response.data === 'object') {
    commit('SET_ITEMS', [response.data, ...state.items]);
    this.$notify(
      'success',
      `Продавец приглашен`,
      'Сообщение с кодом отправлено',
      {duration: 3000, permanent: false},
    );
    return true;
  }

  // no user
  this.$notify(
    'error',
    'Ошибка',
    'Что-то пошло не так',
    {duration: 3000, permanent: false},
  );
  return false;
}

/**
 * Add place.
 *
 * @param state
 * @param commit
 * @param payload
 * @returns {Promise<boolean>}
 * @constructor
 */
export async function ADD_PLACE({state, commit}, payload) {
  return addPlaceToUser.call(this, state, commit, payload, 'vendors');
}

/**
 * Remove place.
 *
 * @param state
 * @param commit
 * @param rootGetters
 * @param payload
 * @returns {Promise<boolean>}
 * @constructor
 */
export async function REMOVE_PLACE({state, commit, rootGetters}, payload) {
  return removePlaceFromUser.call(this, state, commit, payload, 'vendors');
}

/**
 * FETCH_ITEMS_WITH_ONLINE
 *
 * @param {object} state
 * @param {Function} commit
 * @param {object} filter
 * @param {boolean} noGlobals
 * @param {boolean} append
 * @returns {Promise<*>}
 */
export async function FETCH_ITEMS_WITH_ONLINE(
  {state, commit},
  {filter = {}, noGlobals = false, append = false} = {},
) {
  commit('SET_LOADING', true);

  let mergedFilter = cloneDeep(filter);

  if (!noGlobals) {

    mergedFilter = cloneDeep({
      skip: state.skip,
      limit: state.limit,
      where: state.where,
      include: state.include,
      fields: state.fields,
      order: orderFromState(state),
      ...mergedFilter,
    });

    searchStateToFilter(state, mergedFilter);
  }

  const mergedWhere = mergedFilter.where || {};
  const queryFilter = JSON.stringify(mergedFilter);
  const countWhere = JSON.stringify(mergedWhere);

  let countResponse;
  let itemsResponse;

  try {

    itemsResponse = await api.get(
      `/vendors/withOnline?filter=${queryFilter}`,
    );

    countResponse = await api.get(
      `/vendors/countWithOnline?where=${countWhere}`,
    );

  } catch (error) {

    onError({
      error: ApiError.from(error),
      action: 'FETCH_ITEMS',
    });
  }

  if (itemsResponse && countResponse) {

    const itemsData = append
      ? state.items.concat(itemsResponse.data)
      : itemsResponse.data;

    const countData = countResponse.data.count;

    commit('SET_ITEMS', itemsData);
    commit('SET_TOTAL', countData);
    commit('RESET_LOADING');

    onSuccess({
      action: 'FETCH_ITEMS',
    });

    return itemsData;
  }

  commit('RESET_LOADING');
}

/**
 * FETCH_PAGE_WITH_ONLINE
 *
 * @param {object} state
 * @param {Function} commit
 * @param {Function} dispatch
 * @param {number} page
 * @returns {Promise<*>}
 */
export async function FETCH_PAGE_WITH_ONLINE(
  {state, commit, dispatch},
  page = 1,
) {
  const skip = (page - 1) * state.limit;
  commit('SET_SKIP', skip);

  return dispatch('FETCH_ITEMS_WITH_ONLINE');
}

/**
 * SEARCH_ITEMS_WITH_ONLINE
 *
 * @param {object} context
 * @param {object} payload
 * @return {Promise<Array|void>}
 */
export async function SEARCH_ITEMS_WITH_ONLINE(
  {commit, dispatch},
  {query = '', searchBy = null} = {},
) {
  commit('SET_SEARCH_QUERY', query);
  commit('RESET_SKIP');

  if (searchBy)
    commit('SET_SEARCH_BY', searchBy);

  return dispatch('FETCH_ITEMS_WITH_ONLINE');
}
