
export default function () {

  return {
    items: [],
    loading: false,
    total: 0,
    limit: 12,
    offset: 0,
    search: '',
    order: '',
    item: null,
    itemLoading: false,
  };
}
