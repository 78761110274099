import initialState from './state';

export function RESET(state) {
  Object.assign(state, initialState());
}

export function SET_INPUT_TEXT(state, value) {
  state.inputText = value;
}

export function RESET_INPUT_TEXT(state) {
  state.inputText = initialState().inputText;
}

export function SET_MESSAGES_ROOM_NAME(state, value) {
  state.messagesRoomName = value;
}

export function RESET_MESSAGES_ROOM_NAME(state) {
  state.messagesRoomName = initialState().messagesRoomName;
}

export function SET_MESSAGES_LIMIT(state, value) {
  state.messagesLimit = value;
}

export function RESET_MESSAGES_LIMIT(state) {
  state.messagesLimit = initialState().messagesLimit;
}

export function SET_MESSAGES_LOADING(state, value) {
  state.messagesLoading = value;
}

export function RESET_MESSAGES_LOADING(state) {
  state.messagesLoading = initialState().messagesLoading;
}

export function SET_MESSAGES_HAS_MORE(state, value) {
  state.messagesHasMore = value;
}

export function RESET_MESSAGES_HAS_MORE(state) {
  state.messagesHasMore = initialState().messagesHasMore;
}

export function SET_MESSAGES_FIRST_DATE(state, value) {
  state.messagesFirstDate = value;
}

export function RESET_MESSAGES_FIRST_DATE(state) {
  state.messagesFirstDate = initialState().messagesFirstDate;
}

export function SET_MESSAGES(state, value) {
  state.messages = value;
}

export function RESET_MESSAGES(state) {
  state.messages = initialState().messages;
}

export function PREPEND_MESSAGES(state, value) {
  let items = state.messages.slice();
  value.toReversed().forEach(message => {
    const isExist = items.find(existing => message.id === existing.id);
    if (!isExist) items = [message, ...items];
  });
  state.messages = items;
}

export function APPEND_MESSAGES(state, value) {
  let items = state.messages.slice();
  value.forEach(message => {
    const isExist = items.find(existing => message.id === existing.id);
    if (!isExist) items.push(message);
  })
  state.messages = items;
}

export function SET_CONVS(state, value) {
  state.convs = value;
}

export function RESET_CONVS(state) {
  state.convs = initialState().convs;
}

export function ADD_CONVS(state, value) {
  let items = [];
  state.convs.slice().forEach(oldConv => {
    const newConv =
      value.find(c => c.roomName === oldConv.roomName);
    items.push(newConv || oldConv);
  });
  value.forEach(newConv => {
    const fndConv =
      items.find(c => c.roomName === newConv.roomName);
    if (!fndConv) items.push(newConv);
  })
  state.convs = items;
}

export function SET_CONVS_LIMIT(state, value) {
  state.convsLimit = value;
}

export function RESET_CONVS_LIMIT(state) {
  state.convsLimit = initialState().convsLimit;
}

export function SET_CONVS_HAS_MORE(state, value) {
  state.convsHasMore = value;
}

export function RESET_CONVS_HAS_MORE(state) {
  state.convsHasMore = initialState().convsHasMore;
}

export function SET_CONVS_LOADING(state, value) {
  state.convsLoading = value;
}

export function RESET_CONVS_LOADING(state) {
  state.convsLoading = initialState().convsLoading;
}

export function SET_CONVS_TOTAL(state, value) {
  state.convsTotal = value;
}

export function RESET_CONVS_TOTAL(state) {
  state.convsTotal = initialState().convsTotal;
}

export function SET_NO_NEW_MESSAGE(state, roomName) {
  const found = state.convs.find(v => v.roomName === roomName);
  found.hasNewMessage = false;
}

export function PUT_CONV(state, value) {
  if (!value || typeof value !== 'object')
    throw new Error('Payload of PUT_CONV is invalid.');
  let convs = state.convs.slice();
  const index = convs.findIndex(v => v.roomName === value.roomName);
  let updConv = value;
  if (index > -1) {
    updConv = Object.assign({}, convs[index], value);
    convs.splice(index, 1);
  }
  state.convs = [updConv, ...convs];
}
