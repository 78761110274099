import {getFileUrl} from '../utils/index';

export default {
  methods: {
    getFileUrl,
    getBgImageStyles(object, size, blank) {

      const link = this.getFileUrl(
        object,
        size,
        blank,
      );

      if (!link) return '';
      return {
        backgroundImage: `url('${link}')`,
      };
    },
  },
};
