import {defaultColor} from '@/constants/config';

export default function () {

  const themeColor = localStorage.getItem('themeColor')
    ? localStorage.getItem('themeColor')
    : defaultColor;

  const darkTheme = themeColor
    .indexOf('dark') > -1;

  return {
    darkTheme,
    noFooter: false,
  };
}
