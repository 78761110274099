import {messageFromError} from '@/utils/index.js';
import {isEqual} from 'lodash';
import {numWords} from '@/utils/index.js';

export async function SET_SCOPE_AND_CHECK_DEVICES({state, commit}, scope) {
  if (isEqual(state.scope, scope)) return;
  commit('SET_SCOPE', scope);
  commit('SET_LOADING', true);
  commit('RESET_DEVICES');
  let response;
  try {
    response = await this.$api.post(
      `/users/countSocketClients`,
      scope,
    );
  } catch (error) {
    this.$notify(
      'error',
      'Ошибка',
      messageFromError(error),
      {duration: 3000, permanent: false},
    );
    return false;
  } finally {
    commit('RESET_LOADING');
  }
  if (response && response.data && response.data.count !== null) {
    commit('SET_DEVICES', response.data.count);
  } else {
    this.$notify(
      'error',
      'Ошибка',
      'Неизвестный ответ сервера',
      {duration: 3000, permanent: false},
    );
  }
  return state;
}

export async function SEND_COMMAND({state, commit}, data) {
  // идет загрузка
  if (state.loading) return false;
  // нет команды
  if (!data.event) throw new Error('Требуется команда');
  // выставляем статус загрузки
  commit('SET_LOADING', true);
  // сбрасываем кол-во устройств в сети
  commit('RESET_DEVICES');
  // отправка запроса
  let response;
  try {
    response = await this.$api.post(
      `/users/emitSocketEvent`,
      data,
    );
  }
  // ошибка
  catch (error) {
    this.$notify(
      'error',
      'Ошибка',
      messageFromError(error),
      {duration: 3000, permanent: false},
    );
    return false;
  } finally {
    commit('RESET_LOADING');
  }
  // ответ получен
  if (response && response.data && response.data.clients !== null) {
    let clients = response.data.clients;
    commit('SET_DEVICES', clients);
    // есть клиенты
    if (clients > 0) {
      const devicesLabel = numWords(clients, [
        'устройство',
        'устройства',
        'устройств',
      ]);
      this.$notify(
        'info',
        `${clients} ${devicesLabel}`,
        'Команда успешно отправлена',
        {duration: 3000, permanent: false},
      );
    }
    // клиентов нет
    else {
      this.$notify(
        'info',
        'Устройств нет',
        'Некому отправить команду',
        {duration: 3000, permanent: false},
      );
      return false;
    }
  }
  // ошибка
  else {
    this.$notify(
      'error',
      'Ошибка',
      'Неизвестный ответ сервера',
      {duration: 3000, permanent: false},
    );
    return false;
  }
  return true;
}
