
/**
 * Trim.
 *
 * @param string
 * @return {*}
 */
export function trim(string) {

  return string.trim();
}

/**
 * Cut.
 *
 * @param string
 * @param max
 * @param ending
 * @return {string|*}
 */
export function cut(string, max, ending = '\u2026') {

  if (!string)
    return string;

  string = string.trim();

  if (string.length <= max)
    return string;

  max = max || 40;
  ending = ending || '';

  return string.substr(0, max) + ending;
}

/**
 * Truncate.
 *
 * @param string
 * @param max
 * @param ending
 * @return {string|*}
 */
export function truncate(string, max, ending) {

  if (!string)
    return string;

  string = string.trim();

  if (string.length < max)
    return string;

  max = max || 40;
  ending = ending || '';

  let trimmedString = string.substr(0, max);

  trimmedString = trimmedString.substr(
    0,
    Math.min(
      trimmedString.length,
      trimmedString.lastIndexOf(' ')
    ),
  );

  return trimmedString + ending;
}

/**
 * By Default.
 *
 * @param value
 * @param payload
 * @return {*}
 */
export function byDefault(value, payload) {

  return value === ''
    ? payload
    : value;
}

/**
 * Fix RU phone.
 *
 * @param value
 * @return {*}
 */
export function fixPhone(value) {

  return value
    .replace(/^[^+]/, '+7')
    .replace(/^\+[^7]/, '+7')
    .replace(/[^+\d]+/g, '');
}
