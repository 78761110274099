
export function getFileUrl(object, size = null, blank = '') {

  // default path
  let path = '';

  // array must be converted to an object
  object = Array.isArray(object) && 0 in object
    ? object[0]
    : object;

  // object must be an object
  object = Object.isObject(object)
    ? object
    : {};

  // baseUrl must be a string
  const baseUrl = 'server' in object
    ? object.server
    : process.env.VUE_APP_CDN_BASE_URL;

  // getting path
  if (size > 800) {
    if ('thumbs' in object && 1200 in object.thumbs) {
      path = object.thumbs[1200];
    }
  }
  if (!path && size > 500) {
    if ('thumbs' in object && 800 in object.thumbs) {
      path = object.thumbs[800];
    }
  }
  if (!path && size > 200) {
    if ('thumbs' in object && 500 in object.thumbs) {
      path = object.thumbs[500];
    }
  }
  if (!path && size > 0) {
    if ('thumbs' in object && 200 in object.thumbs) {
      path = object.thumbs[200];
    }
  }
  if (!path && object.path) path = object.path;

  // container
  const container = 'container' in object
    ? object.container
    : '';

  // blank
  if (!path) return blank;

  return `${baseUrl}/${container}/${path}`;
}

/**
 * Blob to Data Url.
 *
 * @param blob
 * @return {Promise<*>}
 */
export async function blobToDataUrl(blob) {

  return new Promise((resolve, reject) => {

    const reader = new FileReader();

    reader.onload = event => resolve(event.target.result);
    reader.onerror = error => reject(error);

    reader.readAsDataURL(blob);
  });
}

/**
 * Simple file type.
 *
 * @param file
 * @return {string|undefined}
 */
export function simpleFileType(file) {

  if (!file)
    return undefined;

  const mime = file.mime || file.type || '';

  if (!mime)
    return undefined;

  if (
    mime.indexOf('image/') === 0 &&
    mime.indexOf('svg') === -1
  ) return 'image';

  if (mime.indexOf('video/') === 0)
    return 'video';

  return 'document';
}

export function toCssBg(file, size = null, placeholder = '') {

  const link = getFileUrl(...arguments);

  if (!link)
    return '';

  return {
    backgroundImage: `url('${link}')`,
  };
}
