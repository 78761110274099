import {codeFromError} from '@/utils/index.js';
import {messageFromError} from '@/utils/index.js';

/**
 * SIGN_IN
 *
 * @param state
 * @param commit
 * @returns {Promise<null>}
 * @constructor
 */
export async function SIGN_IN({state, commit}) {
  commit('SET_LOADING', true);

  const credentials = {
    phone: state.login.replace(/^\+/, ''),
    password: state.password,
  };

  let user = null;

  try {

    const {data} = await this.$api.post(
      '/users/login',
      credentials,
    );

    this.$cookies.set('accessToken', data.accessToken, {
      expires: Math.ceil(data.ttl / 86400),
      path: '/',
    });

    user = data;

  } catch (error) {

    console.error(error);
    const message = codeFromError(error) !== 'LOGIN_FAILED'
      ? messageFromError(error, 'Неизвестная ошибка.')
      : 'Вы ввели неверные данные.';
    this.$notify('error', message);
  }

  if (user) {

    commit('SET_USER', user);
    commit('RESET_LOGIN');
    commit('RESET_PASSWORD');

    this.$notify(
      'success',
      'Авторизация успешно выполнена.',
    );

    // Navigate.

    this.$router.push('/app');

    // Socket.

    await this.$socket.connect(user.accessToken);
  }

  commit('RESET_LOADING');

  return user;
}

/**
 * RESOLVE_USER
 *
 * @param commit
 * @returns {Promise<*>}
 * @constructor
 */
export async function RESOLVE_USER({commit}) {
  commit('SET_LOADING', true);
  let user;
  try {
    const {data} = await this.$api.get('/users/me');
    user = data;
  } catch (error) {}
  if (user) {
    commit('SET_USER', user);
    await this.$socket.connect(user.accessToken);
  } else {
    commit('RESET_USER');
    await this.$socket.disconnect();
  }
  commit('RESET_LOADING');
  return user;
}

/**
 * SIGN_OUT
 *
 * @param commit
 * @returns {Promise<void>}
 * @constructor
 */
export async function SIGN_OUT({commit}) {
  commit('SET_LOADING', true);
  await this.$socket.disconnect();
  this.$cookies.remove('accessToken', {path: '/'});
  commit('RESET_USER');
  commit('RESET_LOADING');
  this.$router.push('/user/login');
}

/**
 * REMIND
 *
 * @param commit
 * @param state
 * @returns {Promise<*>}
 * @constructor
 */
export async function REMIND({commit, state}) {
  commit('SET_LOADING', true);

  const credentials = {
    phone: state.login.replace(/^\+/, ''),
  };

  let message;

  try {

    const {data} = await this.$api.post(
      '/users/renewToken',
      credentials,
    );

    message = data;

  } catch (error) {

    console.error(error);
    const message = codeFromError(error) !== 'LOGIN_FAILED'
      ? messageFromError(error, 'Неизвестная ошибка.')
      : 'Вы ввели неверные данные.';
    this.$notify('error', message);
  }

  if (message) {

    this.$notify(
      'success',
      message,
    );

    // Navigate.

    this.$router.push('/user/login');
  }

  commit('RESET_LOADING');

  return message;
}
