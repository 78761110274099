import VSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import {ItemEditor} from 'vuex-loopback';
import {ItemsLoader} from 'vuex-loopback';
import VueAspectRatio from 'vue-aspect-ratio';
import RowCard from '@/components/cards/row-card';
import DatePicker from '@/components/date/date-picker';
import TimePicker from '@/components/date/time-picker';
import Breadcrumb from '@/components/common/breadcrumb';
import EmptyStatus from '@/components/status/empty-status';
import PageContainer from '@/components/page/page-container';
import PagePagination from '@/components/page/page-pagination';
import SingleLightBox from '@/components/image/single-lightbox';
import FormContainer from '@/components/form/form-container.vue';
import SearchSelector from '@/components/selector/search-selector';
import FormInputGroup from '@/components/form/form-input-group.vue';
import OptionsSelector from '@/components/selector/options-selectior';
import PerfectScrollbar from '@/components/common/perfect-scrollbar.vue';

export default ({Vue}) => {
  Vue.component('v-select', VSelect);
  Vue.component('v-aspect-ratio', VueAspectRatio);
  Vue.component(RowCard.name, RowCard);
  Vue.component(DatePicker.name, DatePicker);
  Vue.component(TimePicker.name, TimePicker);
  Vue.component(Breadcrumb.name, Breadcrumb);
  Vue.component(ItemEditor.name, ItemEditor);
  Vue.component(ItemsLoader.name, ItemsLoader);
  Vue.component(EmptyStatus.name, EmptyStatus);
  Vue.component(FormContainer.name, FormContainer);
  Vue.component(PageContainer.name, PageContainer);
  Vue.component(FormInputGroup.name, FormInputGroup);
  Vue.component(SingleLightBox.name, SingleLightBox);
  Vue.component(PagePagination.name, PagePagination);
  Vue.component(SearchSelector.name, SearchSelector);
  Vue.component(OptionsSelector.name, OptionsSelector);
  Vue.component(PerfectScrollbar.name, PerfectScrollbar);
}
