var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-card",
    {
      staticClass: "row-card",
      class: _vm.classes,
      attrs: { "no-body": "" },
      on: {
        click: function ($event) {
          return _vm.$emit("click")
        },
      },
    },
    [
      _vm.selected
        ? _c("div", { class: `accent-tr-${_vm.triangleSize}` })
        : _vm._e(),
      _vm.image !== undefined
        ? _c("image-tile", {
            staticClass: "row-card__image",
            attrs: {
              width: _vm.imageWidth_,
              height: _vm.imageHeight_,
              link: _vm.$u.getFileUrl(_vm.image, 200),
              "placeholder-link": _vm.imagePlaceholderLink,
            },
          })
        : _vm._e(),
      _c("div", { staticClass: "pl-2 d-flex flex-grow-1 min-width-zero" }, [
        _c(
          "div",
          {
            class: [
              "d-flex",
              "card-body",
              "flex-column",
              "flex-lg-row",
              "min-width-zero",
              "align-self-center",
              "align-items-lg-center",
              "justify-content-between",
            ],
          },
          [
            _c(
              "div",
              {
                staticClass: "column column__title row-card__title",
                class: _vm.titleClass,
              },
              [
                _c("div", { domProps: { innerHTML: _vm._s(_vm.title) } }),
                _vm.titleBody
                  ? _c(
                      "div",
                      {
                        staticClass: "row-card__title-body",
                        class: _vm.titleBodyClass,
                      },
                      [_vm._v(" " + _vm._s(_vm.titleBody) + " ")]
                    )
                  : _vm._e(),
                _vm.titleCaption
                  ? _c(
                      "div",
                      {
                        staticClass: "row-card__title-caption",
                        class: _vm.titleCaptionClass,
                      },
                      [_vm._v(" " + _vm._s(_vm.titleCaption) + " ")]
                    )
                  : _vm._e(),
              ]
            ),
            !_vm.noSubtitle && _vm.subtitle !== undefined
              ? _c(
                  "div",
                  {
                    staticClass: "column font-size-4",
                    class: _vm.subtitleClass,
                  },
                  [_vm._v(" " + _vm._s(_vm.subtitle) + " ")]
                )
              : _vm._e(),
            _vm.body !== undefined
              ? _c("div", {
                  staticClass: "column font-size-4",
                  class: _vm.bodyClass,
                  domProps: { innerHTML: _vm._s(_vm.body) },
                })
              : _vm._e(),
            _vm.caption !== undefined
              ? _c(
                  "div",
                  { staticClass: "column text-small", class: _vm.captionClass },
                  [_vm._v(" " + _vm._s(_vm.caption) + " ")]
                )
              : _vm._e(),
            _vm.label !== undefined
              ? _c(
                  "div",
                  { staticClass: "column text-small no-line-height" },
                  [
                    _vm.label
                      ? _c(
                          "b-badge",
                          {
                            class: _vm.labelClass || "bg-secondary text-white",
                            style: {
                              backgroundColor: _vm.labelClass,
                              color: "white",
                            },
                            attrs: { pill: "", variant: "none" },
                          },
                          [_vm._v(" " + _vm._s(_vm.label) + " ")]
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm.$slots.action ? _vm._t("action") : _vm._e(),
          ],
          2
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }