<template>

  <!-- Form Input Group -->
  <div
    class="form-input-group">

    <form-input-validator
      :cond="cond"
      :value="value">

      <b-input-group>

        <slot
          name="prepend"
          slot="prepend"/>

        <slot/>

        <slot
          name="append"
          slot="append"/>

      </b-input-group>

    </form-input-validator>

  </div>

</template>

<script>

  import FormInputValidator from '@/components/form/form-input-validator.vue';

  export default {
    name: 'form-input-group',
    props: {
      value: {},
      cond: {
        type: Function,
      },
    },
    components: {
      FormInputValidator,
    },
  };

</script>

<style lang="scss">

  .form-input-group {
    color: inherit;
  }

</style>
